import { init as setup_popup, destroy_popup } from 'ui/popup';
import { get as get_cache } from 'utils/cache';
import { esc_html } from 'utils/strings';

import { set_placeholders } from 'booking-widget/v2/common/util';
import {
    TOUR_PICKER_TEMPLATE,
    TOUR_PICKER_OPTION
} from 'booking-widget/v2/templates/waitlist';

// only creates at most one popup element per page
export const init = (options) => {
    if (!options) {
        options = {};
    }
    destroy_tourpicker();

    let display_el = options.display_el ? options.display_el : $();
    let list_html = '';
    for (let tour of options.data) {
        list_html += set_placeholders(TOUR_PICKER_OPTION, {
            tour_id: esc_html(tour.id),
            tour_name: esc_html(tour.name)
        });
    }
    let checkboxes_el;
    setup_popup({
        html: set_placeholders(TOUR_PICKER_TEMPLATE, {tours_list: list_html}),
        on_setup: function(popup_el) {
            let tours_el = $('.bw-multiselect', popup_el);
            checkboxes_el = $('input[type="checkbox"]', tours_el);
            $('input[type="checkbox"]', tours_el).on('change', function() {
                if (options.on_change) {
                    options.on_change(checkboxes_el.filter(':checked'));
                }
            });
        },
        on_shown: function(e, params) {
            let popup_el = params.el;
            let opener_el = params.opener_el;
        },
        on_hidden: function(popup_el) {
            display_el.attr('data-touched', 1);
            if (options.on_hidden) {
                options.on_hidden(checkboxes_el.filter(':checked'));
            }
        }
    });
}


export const destroy_tourpicker = () => {
    destroy_popup();
}