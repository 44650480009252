import { 
    local_to_tz_display,
    convertISOtoDate,
} from 'utils/date';

import {
    update_day_el
} from 'booking-widget/v2/common/util';

import { DATE_ITEM_TEMPLATE } from 'booking-widget/v2/templates/shared';

class DateCarousel {
    constructor(options) {
        if (!options) {
            options = {};
        }
        this.el = $('.bw-date-carousel', options.context_el);
        this.num_dates = 13; // 3 hidden | 7 visible | 3 hidden
        this.mid_index = 6;
        this.items_el = $('.bw-date-carousel__items', this.el);
        this.curr_date = options.start_time;
        this.on_date_select = options.on_date_select;
        this.prev_el = $('.bw-date-carousel__button--prev', this.el);
        this.next_el = $('.bw-date-carousel__button--next', this.el);


        const hst_now_dt = local_to_tz_display(new Date(), options.utc_offset);
        this.min_date = new Date(hst_now_dt.toDateString());
        this.min_date.setDate(this.min_date.getDate() + 1);

        let date = convertISOtoDate(options.start_time + ' 00:00:00');
        for (let i = -this.mid_index; i <= this.mid_index; i++) {
            const item_dt = new Date(date);
            item_dt.setDate(date.getDate() + i);
            let day_el = $(DATE_ITEM_TEMPLATE);
            this.items_el.append(day_el);
            this._update_day_el(day_el, item_dt);
            if (i == 0) {
                day_el.addClass('bw-middle-child');
            }
        }
        
        let self = this;
        this.prev_el.off('click').on('click', function(e) {
            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return; 
            }
            self.move(-1);
        });
        this.next_el.off('click').on('click', function(e) {
            e.preventDefault();
            self.move(1);
        });
        $('.bw-date-item').off('click').on('click', function(e) {
            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return;
            }
            self.go_to($(this).index());
        });

        this.items_el.off('transitionend').on('transitionend', function(e) {
            self.items_el.removeClass('animate');
            // recycle and reset
            let dir = '';
            if (self.items_el.hasClass('doing-next')) {
                dir = 'next';
            }
            else if (self.items_el.hasClass('doing-prev')) {
                dir = 'prev';
            }
            
            let positions = 0;
            if (dir != '') {
                positions = 1;
                if (self.items_el.hasClass(dir + '--2')) {
                    positions = 2;
                }
                if (self.items_el.hasClass(dir + '--3')) {
                    positions = 3;
                }
            }

            for (let i = 0; i < positions; i++) {
                self._recycle(dir);
            }
            self.items_el
                .removeClass(dir + '--' + positions)
                .removeClass('doing-' + dir)
            ;
        });

        let curr_dt = convertISOtoDate(this.curr_date + ' 00:00:00');
        this.disable_el(this.prev_el, curr_dt <= this.min_date);
    }

    // get the first or last element, set it to a new date, and place it on the other side.
    _recycle(dir) {
        let items = $('.bw-date-item', this.items_el);
        let recycle_el = dir == 'next' ? items.first() : items.last();
        let date_ref_el = dir == 'next' ? items.last() : items.first();
        let date = convertISOtoDate(date_ref_el.attr('data-date') + ' 00:00:00');
        const new_date = new Date(date);
        const date_adjust = dir == 'next' ? 1 : -1;
        new_date.setDate(date.getDate() + date_adjust);

        recycle_el.detach();
        this._update_day_el(recycle_el, new_date);
        if (dir == 'next') {
            this.items_el.append(recycle_el);
        }
        else {
            this.items_el.prepend(recycle_el);
        }
    }
    
    _update_day_el(day_el, date) {
        update_day_el(day_el, date);
        this.disable_el(day_el, date < this.min_date);
    }

    move(slots_to_move) {
        if (slots_to_move == 0 || this.items_el.hasClass('animate')) {
            return;
        }
        let dir = slots_to_move > 0 ? 'next' : 'prev';

        // classes look like: doing-next next--1
        this.items_el.addClass('animate').addClass(`doing-${dir} ${dir}--${Math.abs(slots_to_move)}`);

        // move the middle class to the element that will be the middle after the animation
        let future_mid_index = this.mid_index + slots_to_move;
        let future_mid_el = $('.bw-date-item:eq(' + future_mid_index + ')', this.items_el);
        $('.bw-date-item.bw-middle-child', this.items_el).removeClass('bw-middle-child');
        future_mid_el.addClass('bw-middle-child');
        this.curr_date = future_mid_el.attr('data-date');

        let curr_dt = convertISOtoDate(this.curr_date + ' 00:00:00');
        this.disable_el(this.prev_el, curr_dt <= this.min_date);

        if (this.on_date_select) {
            this.on_date_select(this.curr_date);
        }
    }

    go_to(index) {
        let rel_index = index - this.mid_index;
        this.move(rel_index);
    }

    go_to_date(date_string) {
        let str = date_string;
        let new_el = $('.bw-date-item[data-date="' + str + '"]', this.items_el);
        // scroll to a little higher than the date carousel 
        $([document.documentElement, document.body]).animate({
            scrollTop: this.el.offset().top - 15
        }, 300);
        
        // this.el[0].scrollIntoView();
        this.go_to(new_el.index());
    }

    disable_el(el, disable) {
        if (disable) {
            el.addClass('disabled');
            el.removeAttr('href');
        }
        else {
            el.removeClass('disabled');
            el.attr('href', '#');
        }
    }
}

export default DateCarousel;