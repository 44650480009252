import { init as init_phone_input, is_possible_number, get_number } from 'booking-widget/v2/widgets/phone-input';
import { setup_form as setup_contact_form, validate_form as validate_contact_form } from 'booking-widget/v2/widgets/contact-form';


import { strip_tags, esc_html, force_default_name_capitalization_format } from 'utils/strings';
import { get as get_cache, cache } from 'utils/cache';
import setup_hotels_autocomplete from 'data/hotel';
import { 
    patch as patch_res, 
    post as post_res
} from 'data/reservation';
import { update_data_targets, form_to_object } from 'ui/data';
import { 
    show_loading as _show_loading, 
    hide_loading as _hide_loading, 
} from 'ui/visibility';

import { 
    set_placeholders,
    show_error,
    scroll_to
} from 'booking-widget/v2/common/util';
import { MODAL_TEMPLATE } from 'booking-widget/v2/templates/shared';
import {
    FOOD_PREFERENCE,
    PASSENGER_ROW,
} from 'booking-widget/v2/templates/info';
import {
    init as setup_modal,
} from 'ui/modal';


export const setup_form = (els, opts) => {
    let form_el = els.form_el;
    let error_el = els.error_el;

    setup_hotels_autocomplete({
        context_el: form_el,
        min_chars: 3
    });

    if (!opts.is_charter && !opts.is_gift) {
        setup_passenger_rows($('.bw-passengers', form_el), opts);
    }
    /*
    Removed charter booking, so .bw-primary-contact-name has been removed from template.
    else if (opts.is_charter) {
        setup_passenger_rows($('.bw-primary-contact-name', form_el), $.extend(true, {}, opts, {
            adult: 1,
            child: 0,
            toddler: 0,
            hide_food_pref: true,
            adult_label: ''
        }));
        $('select[name="contact_method"]').on('change', function() {
            $(this).toggleClass('bw-selected', $(this).val() !== '');
        })
    }
    */

    $('#bw-enable-group-name', form_el).on('change', function() {
        let group_el = $(this).closest('.bw-group-section');
        let group_name_el = $('input[name="reservation_group_name"]', group_el);
        if ($(this).is(':checked')) {
            $('.bw-group-name', group_el).show();
            group_name_el.removeAttr('disabled');
            group_name_el[0].scrollIntoView({ behavior: 'smooth' });
        }
        else {
            $('.bw-group-name', group_el).hide();
            group_name_el.attr('disabled', 'disabled');
        }
    });

    $('input[data-has-confirm]', form_el).on('change', function(e) {
        let other_selector = $(this).attr('data-has-confirm');
        let confirm_el = $(other_selector, form_el);
        let confirm_val = confirm_el.val();
        let warning_el = get_warning_el($(this));
        if (this.value != confirm_val && confirm_val != '') {
            let warning_type = (this.name.indexOf('phone') === -1) ? 'Email addresses' : 'Phone numbers';
            warning_el.text(warning_type + " do not match").show();
        }
        else {
            warning_el.hide().text('');
            get_warning_el(confirm_el).hide().text('');
        }
    })

    $('input', form_el).on('invalid', function() {
        let submit_button_el = $('input[type="submit"]', form_el);
        submit_button_el.val(submit_button_el.attr('data-original'));
    });

    let is_submitting = false;
    form_el.on('submit', function(e) {
        e.preventDefault();
        if (is_submitting) {
            return;
        }

        error_el.hide();
        $('input[name]', form_el).removeClass('bw-error');

        is_submitting = true;
        let errors = {};

        const is_valid = form_el[0].checkValidity();

        if (!is_valid) {
            form_el[0].reportValidity();
            $(':invalid').not('form').addClass('bw-error');
            is_submitting = false;
            return;
        }
        
        let form_data = form_to_object(form_el);
        form_data.trip_id = opts.trip_id;
        form_data.tour_id = opts.tv_data ? opts.tv_data.tour_id : '';

        // validate
        let adult = parseInt(opts.adult) || 0;
        let child = parseInt(opts.child) || 0;
        let toddler = parseInt(opts.toddler) || 0;

        if (adult == 0) {
            errors.passenger_count = 'Invalid passenger count.';
        }

        if (opts.tv_data && opts.tv_data.vessel && (adult + child + toddler > opts.tv_data.vessel[0].capacity_max)) {
            errors.capacity_max = 'The number of passengers exceeds the vessel\'s capacity.';
        }

        if (!$.isEmptyObject(errors)) {
            show_error(error_el, errors);
            is_submitting = false;
            return;
        }

        // go through each name and food pref since an unchecked food pref doesn't show up in form_data.
        form_data.contact_id = [];
        form_data.passenger_id = [];
        form_data.full_name = [];
        form_data.food_preference = [];
        form_data.is_child = [];
        
        const num_pax = adult + child + toddler;
        let contact_els = $('input[data-contact-idx]', form_el);
        for (let idx = 0; idx < num_pax; idx++) {
            if (opts.is_gift) {
                form_data.contact_id = [''];
                form_data.passenger_id = [''];
                form_data.is_child = ['off'];
                form_data.full_name = [''];
                form_data.food_preference = [''];
                break;
            }
            let full_name_el = contact_els.filter('input[name="full_name"][data-contact-idx="' + idx + '"]');
            let food_pref_el = contact_els.filter('input[name="food_preference"][data-contact-idx="' + idx + '"]:checked');

            let contact_id = full_name_el.is('[data-contact-id]') ? full_name_el.attr('data-contact-id') : '';
            let passenger_id = full_name_el.is('[data-passenger-id]') ? full_name_el.attr('data-passenger-id') : '';
            let is_child = full_name_el.attr('data-is-child');

            if (full_name_el.val().length > 0 && full_name_el.val().indexOf(' ') === -1) {
                errors['full_name_' + idx] = 'A first and last name is required.';
            }

            form_data.contact_id.push(contact_id);
            form_data.passenger_id.push(passenger_id);
            form_data.is_child.push(is_child);
            form_data.full_name.push(full_name_el.length > 0 ? full_name_el.val() : '');
            form_data.food_preference.push(food_pref_el.length > 0 ? food_pref_el.val() : '');
        }

        if (!$.isEmptyObject(errors)) {
            show_error(error_el, errors);
            // need to manually show error messages and highlight bc we're not using the input name
            for (const key in errors) {
                $('.info-error-message[data-error="' + key + '"]').text(errors[key]).show();
                if (key.startsWith('full_name_')) {
                    $('#' + key.replaceAll('_', '-')).addClass('bw-error');
                }
            }
            is_submitting = false;
            return;
        }

        form_data.adult = adult;
        form_data.child = child;
        form_data.toddler = toddler;
        form_data.is_charter = opts.is_charter;

        opts.on_submit(form_data);
    });
    
    populate_form(form_el, opts);
}


function get_warning_el(input_el) {
    let warning_el = input_el.parent().find('.bw-warning').not(input_el);
    if (warning_el.length == 0) {
        warning_el = input_el.parent().parent().find('.bw-warning').not(input_el);
    }
    return warning_el;
}

function populate_form(form_el, opts) {
    const data = $.extend({}, opts);
    if (data.full_name && Array.isArray(data.full_name)) {
        for (let i = 0; i < data.full_name.length; i++ ) {
            data['full_name_' + i] = data.full_name[i];
        }
    }
    if (data.food_preference && Array.isArray(data.food_preference)) {
        for (let i = 0; i < data.food_preference.length; i++ ) {
            if (data.food_preference[i] != '') {
                data['food_preference_' + i + '_' + data.food_preference[i]] = 1;
            }
        }
    }

    if (data.reservation_group_name && data.reservation_group_name.length > 0) {
        let toggle_el = $('input[name="reservation_group_name"]', form_el);
        toggle_el.removeAttr('disabled')
        $('#bw-enable-group-name', form_el).prop('checked', false).trigger('click');
        $('.bw-group-name', form_el).show();
    }

    if ('opt_in' in data && data.opt_in == 0) {
        $('#opt-in-sms', form_el).prop('checked', true).trigger('click');
    }

    update_data_targets(data);
}

function setup_passenger_rows(el, options) {
    options.adult = options.adult ? options.adult : 0;
    options.child = options.child ? options.child : 0;
    options.toddler = options.toddler ? options.toddler : 0;
    options.tour = options.tour ? options.tour : {};

    let adult_label = ('adult_label' in options) ? options.adult_label : 'Adult ';
    let hide_food_pref = options.hide_food_pref ? options.hide_food_pref : false;

    let idx = 0;
    let food_preferences = false;
    if (!hide_food_pref) {
        food_preferences = get_cache('booking_settings-food_preferences');
    }
    let food_preference_row_html = '';
    if (food_preferences) {
        for(let i in food_preferences) {
            let has_popup = ('popup' in food_preferences[i]);
            let food_modal_id ='bw-food-menu-modal-' + food_preferences[i]['key'];
            food_preference_row_html += set_placeholders(FOOD_PREFERENCE, {
                food_pref_key: esc_html(food_preferences[i]['key']),
                food_pref_value: esc_html(food_preferences[i]['value']),
                hide_show_class: !has_popup ? 'bw-d-none' : '',
                food_pref_modal_id: food_modal_id
            })
        }
    }

    for (let i = 0; i < options.adult; i++) {
        let atts = 'data-is-child="off" ';
        let asterisk = '';

        if (i == 0) {
            atts += 'required';
            asterisk += '*';
        }
        let count_label = i + 1;
        if (options.adult == 1) {
            count_label = '';
        }
        if (i > 0) {
            atts += ' autocomplete="off"';
        }
        el.append(set_placeholders(PASSENGER_ROW, {
                food_preferences: food_preference_row_html,
                idx: idx,
                is_child: 'off',
                is_child_label: adult_label + count_label,
                full_name_atts: atts,
                required_asterisk: asterisk
            }
        ));
        idx++;
    }

    let child_count = Number(options.child) + Number(options.toddler)
    for (let i = 0; i < child_count; i++) {
        let atts = 'data-is-child="on" ';
        let asterisk = '';

        el.append(set_placeholders(PASSENGER_ROW, {
                food_preferences: food_preference_row_html,
                idx: idx,
                is_child: 'on',
                is_child_label: 'Child ' + (i+1),
                full_name_atts: atts,
                required_asterisk: asterisk,
            }
        ));
        idx++;
    }

    if (hide_food_pref) {
        $('.bw-flex-lg-basis-33', el)
            .removeClass('bw-flex-lg-basis-33')
            .addClass('bw-flex-lg-basis-50')
        ;
        $('.bw-food-preferences').hide();
    }

    // adjust alignment so warning message doesn't mess up the layout.
    $('.bw-row').each(function() {
        let el = $(this);
        let passengers_col = $('.bw-food-preferences', el);
        if (passengers_col.height() > passengers_col.prev().height()) {
            el.addClass('bw-row--align-unset');
        }
    })

    let food_preference_rows_el = $('.bw-food-preferences', el);
    if (options.tour.has_meal != 1) {
        food_preference_rows_el.hide();
        $('input[name="food_preference"]', food_preference_rows_el).attr('disabled', 'disabled');
    }

    $('input[name="full_name"]', el).off('change').on('change', function(e) {
        let warning_el = $(this).parent().find('.bw-warning').not($(this));
        let error = $(this).val().trim().indexOf(" ") === -1;
        if (error) {
            warning_el.text("Please enter your full name.").show();
        }
        else {
            warning_el.hide().text('');
        }
        let el_val = $(this).val();
        $(this).val(force_default_name_capitalization_format(el_val, true));
    });

    if (!hide_food_pref) {
        $('input[name="food_preference"]', food_preference_rows_el).off('change').on('change', function(e) {
            let el = $(this).closest('.bw-food-preferences');
            $('input[name="food_preference"]', el).not(this).prop('checked', false);
        });
    }

    if (food_preferences) {
        for(let i in food_preferences) {
            let food_modal_id ='bw-food-menu-modal-' + food_preferences[i]['key'];
            if (('popup' in food_preferences[i])) {
                let popup_content_html = strip_tags(food_preferences[i]['popup'], '<button><div><span><p><a><strong><i>');
                $('.bw-food-preference-popup-container').html(popup_content_html);
                setup_modal({
                    html: MODAL_TEMPLATE.replace(/\[modal_id\]/g, esc_html(food_modal_id)),
                    on_shown: function(e, params) {
                        let food_modal_el = $('#'+food_modal_id);
                        $('.bw-modal__content', food_modal_el).html(popup_content_html);
                        $('.bw-modal__footer', food_modal_el).empty();
                    }
                });
            }
        }
    }
}