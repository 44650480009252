// ts-modal
// add data-ts-modal-open=<id> to elements to open modal
// add data-ts-modal-close to elements within a modal to close it.
// as lean as can be, pass in your own html.
/*
*/
export const init = (options) => {
    let opts = $.extend({
        html: '', // required
        classes: '',
        on_shown: function() {},
        on_hidden: function() {},
    }, (options || {}));

    let el = $(opts.html)
        .addClass(opts.classes)
        .addClass('ts-modal')
        .attr('aria-hidden', true)
    ;
    $('body').append(el);

    let id = el.attr('id');
    if (!id) {
        id = 'ts-modal-0';
    }

    setup_opener($('[data-ts-modal-open="' + id + '"]'));

    // with modal within modal setups, this triggers twice on the child modal.  check for is visible
    $('[data-ts-modal-close]').on('click', el, function(e) {
        if (e.target.hasAttribute('data-ts-modal-close') && $(this).closest('.ts-modal').is(':visible')) {
            e.preventDefault();
            e.stopPropagation();
            hide($(this).closest('.ts-modal'));
            return false;
        }
    });
    el.on('ts-modal-shown', function(e, params) {
        if (opts.on_shown) {
            opts.on_shown(e, params);
        }
    });
    el.on('ts-modal-hidden', function(e) {
        if (opts.on_hidden) {
            opts.on_hidden(e);
        }
    });
};


export const show = (el, opener_el) => {
    if (el.is(':visible')) {
        return;
    }
    el.show()
      .attr('aria-hidden', false)
    ;
    $('html').addClass('ts-modal--showing');
    el.css({
        position: 'fixed',
        display: 'block'
    })
    el.trigger('ts-modal-shown', { opener_el: opener_el });
};

export const hide = (el) => {
    el.hide()
      .attr('aria-hidden', true)
    ;
    $('html').removeClass('ts-modal--showing');
    el.css({
        display: 'none'
    })
    el.trigger('ts-modal-hidden');
};

// call this only once per element that opens the modal
export const setup_opener = (opener_el) => {
    opener_el.off('click').on('click', function(e) {
        e.preventDefault();
        let id = $(this).attr('data-ts-modal-open');
        show($('#' + id), $(this));
    });
}

export const html_template = `
    <div class="ts-modal" id="[modal_id]">
        <div class="ts-modal__overlay" tabindex="-1" data-ts-modal-close>
            <div class="ts-modal__container" role="dialog" aria-modal="true">
                <header>[modal_title]</header>
                <main class="ts-modal__content">[modal_content]</main>
                <footer class="ts-modal__footer">[modal_footer]</footer>
            </div>
        </div>
    </div>
`;