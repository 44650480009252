import { get as get_data } from 'data/data';
import { get_settings as get_agency_settings } from 'data/agency';
import { 
    get_gift_certificate as _get_gift_certificate,
    patch as patch_res, 
    post as post_res
} from 'data/reservation';
import { get_calculated as get_calculated_prices } from 'data/tour-price';
import { post as transaction_post } from 'data/transaction';
import { get as _get_passengers } from 'data/passenger';

import { get as get_cache, cache } from 'utils/cache';

import { 
    get_token as cc_get_token,
    eval_response as cc_eval_response,
} from 'widgets/payment-creditcard';

import { STEPS, show_fullscreen_modal, load_prev_step } from 'booking-widget/v2/bw';
import { setup_close_btn } from 'booking-widget/v2/widgets/nav';
import { get_tours, get_trip, sanitize_cart_data } from 'booking-widget/v2/common/data';
import { 
    format_calculated_prices,
    set_placeholders,
    setup_selected_tour_day,
    show_error,
    clear_errors,
    show_loading,
    hide_loading,
    split_full_name, 
    format_currency,
} from 'booking-widget/v2/common/util';

import {
    PAYMENT_TEMPLATE,
    TC_AGREE_CHECKBOXES,
    PAYMENT_ERROR_RES_SUCCESS,
    PAYMENT_ERROR_GENERIC
} from 'booking-widget/v2/templates/payment';

import { CONTACT_INFO_FIELDS } from 'booking-widget/v2/templates/shared';

import { setup as setup_nav } from 'booking-widget/v2/widgets/nav';
import { 
    setup_form as setup_contact_form, 
    validate_form as validate_contact_form
} from 'booking-widget/v2/widgets/contact-form';

export const load = (options, bw_events) => {
    let opts = $.extend(true, {
        tour_id: false,
        trip_id: false,
        is_charter: false,
        vessel_type: false
    }, (options || {}));

    const widget_el = show_fullscreen_modal(opts.context_el);
    let form_el = $();
    let error_el = $();
    let form_data = {};
    let res_num = '';
    let prices = {};

    show_loading(widget_el, true);

    if (opts.amount_only) {
        $.when(
            get_data({endpoint: 'transaction/payment_config'}), 
        ).then(function(payment_config_json) {
            bw_events.on_loaded(opts);
            if (payment_config_json.success) {
                cache('payment_config', payment_config_json.data);
                
                // load the Accept script
                let head = document.getElementsByTagName('head')[0];
                let script = document.createElement('script');
                script.src = payment_config_json.data.js_url;
                script.type = 'text/javascript';
                head.appendChild(script);

            }
            form_data = sanitize_cart_data(opts);
            prices = {
                amount_only: opts.amount_only,
                amount_only_taxes: opts.amount_only_taxes,
                amount_only_fees: opts.amount_only_fees,
            }
            render_page_gc(payment_config_json);

            $('.bw-mobile-submit-bar__button').on('click', function(e) {
                e.preventDefault();
                form_el.trigger('submit');
            });
            hide_loading(widget_el, true);

        }, function() {
            hide_loading(widget_el, true);
        });
    }
    else {
        if (!has_required_params(opts)) {
            load_prev_step(opts)
            return;
        }
        if (!opts.is_child) {
            bw_events.on_load_error(opts);
            return;
        }

        let child_count = Number(opts.child) + Number(opts.toddler);

        const tours_params = {
            id: opts.tour_id, 
            cache_name_prefix: 'tour-', 
            fields_append: 'has_meal'
        }

        $.when(get_trip(opts.trip_id)).then(function(tv_data) {
            if (tv_data == null) {
                load_prev_step(opts)
                return;
            }
            const prices_params = { 
                data: { 
                    trip_id: opts.trip_id, 
                    adult_count: opts.is_charter == true ? tv_data.vessel[0].capacity_max : opts.adult,
                    child_count: opts.is_charter == true ? 0 : child_count
                }
            };
            if (opts.promo_code) {
                prices_params.data.code = opts.promo_code;
            }
            $.when(
                get_tours(tours_params), 
                get_calculated_prices(prices_params), 
                get_gift_certificate(opts.gift_certificate_id),
                get_data({endpoint: 'transaction/payment_config'}),
                get_agency_settings())
            .then(function(tours_json, prices_json, gc_data, payment_config_json, settings_cached) {
                cache('calculated_prices', prices_json);
                const tour = tours_json.data ? tours_json.data : {};
                prices = format_calculated_prices(prices_json);
                let total_price = Number(prices.total_price);
                if (gc_data) {
                    let gc_amount = Number(gc_data.amount_payment_paid);
                    total_price -= gc_amount;
                    prices.total_price = Number(total_price).toFixed(2);
                    prices.gift_code_amount = gc_amount.toFixed(2);
                    prices.gift_code = gc_data.number;
                }

                if (total_price > 0 && payment_config_json.success) {
                    cache('payment_config', payment_config_json.data);

                    // load the Accept script
                    let head = document.getElementsByTagName('head')[0];
                    let script = document.createElement('script');
                    script.src = payment_config_json.data.js_url;
                    script.type = 'text/javascript';
                    head.appendChild(script);
                    
                }

                form_data = sanitize_cart_data(opts);

                if (form_data.trip_id) {
                    form_data.tour_vessel_id = form_data.trip_id;
                    delete form_data.trip_id;
                }
                if (prices_json.meta.promo_code_id) {
                    form_data.promo_code_id = prices_json.meta.promo_code_id;
                }
                form_data.payment_amount = Number(prices.total_price);
                render_page_tour(tv_data, tour, settings_cached, prices);

                $('.bw-terms-link', widget_el).on('click', function(e) {
                    e.preventDefault();

                    $(this).toggleClass('showing');
                    $('.bw-terms-conditions', widget_el).toggle();
                })
                $('.bw-mobile-submit-bar__button').on('click', function(e) {
                    e.preventDefault();
                    form_el.trigger('submit');
                });

                hide_loading(widget_el, true);
                bw_events.on_loaded({prices: prices});
            }, function() {
                hide_loading(widget_el, true);
            });

        })
    }

    function render_page_tour(tv_data, tour, settings, prices) {
        hide_loading(widget_el, true);

        let passenger_names = [];
        let primary_name = '';
        for (let i = 0; i < opts.contact_id.length; i++) {
            let food_pref_str = '';
            if ((i < opts.food_preference.length && opts.food_preference[i] != '') ) {
                food_pref_str = ' - <em>' + opts.food_preference[i] + '</em>';
            }
            let is_child_str = ' (adult)';
            if ((i < opts.is_child.length) && opts.is_child[i] == 'on') {
                is_child_str = ' (child)';
            }
            let name = '';
            if (opts.first_name && i < opts.first_name.length) {
                name = opts.first_name[i];
            }
            if (opts.last_name && i < opts.last_name.length && opts.last_name[i].length > 0) {
                name += ' ' + opts.last_name[i];
            }
            if (i == 0) {
                primary_name = name;
            }
            if (name == '') {
                name = 'Passenger ' + (i+1);
            }
            passenger_names.push(name  + is_child_str + food_pref_str);
        }

        let location = (tv_data.location && tv_data.location.length > 0) ? tv_data.location[0].name : '';

        let child_count = Number(opts.child) + Number(opts.toddler);
        const modify_url = STEPS.info.href();

        const payment_el = $(set_placeholders(PAYMENT_TEMPLATE, {
            selected_tour_params: {
                message: 'Departure: ' + location,
                tv_data: tv_data,
                tour: tour,
                prices: prices,
                adult_count: opts.adult,
                child_count: child_count,
                passenger_names: passenger_names,
                primary_name: primary_name,
                primary_email: opts.email,
                primary_phone: opts.phone,
                guest_count: opts.contact_id.length,
                use_tour_info_as_highlights: ($(window).width() < 768),
                is_charter: opts.is_charter,
                extra_tag_html: '&nbsp;<a href="' + modify_url + '" class="bw-info-link">[modify]</a>'
            },
            submit_bar_total: format_currency(prices.total_price),
            submit_bar_link_text: 'Make Payment'
        }));

        widget_el.append(payment_el);
        if (child_count > 0 && !opts.is_charter) {
            $('.bw-selected-tour__price-list__line--child', widget_el).show();
        }
        if (Number(prices.gift_code_amount) > 0) {
            $('.bw-selected-tour__price-list__line--gift', widget_el).show();
        }
        if (Number(prices.discount) > 0) {
            $('.bw-selected-tour__price-list__line--discount', widget_el).show();
        }

        if (!opts.is_charter) {
            $('.bw-selected-tour__passengers', widget_el).show();
        }
        else {
            $('.bw-selected-tour__contact', widget_el).show();
        }

        form_el = $('.bw-form', widget_el);
        error_el = $('.bw-form-errors', widget_el)
    
        setup_selected_tour_day(payment_el, tv_data.start_time, false);

        const policy_link = $('.bw-cancel-policy-link', payment_el);
        const policy_el = $('.bw-cancel-policy', payment_el);

        policy_link.on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('bw-underline');
            policy_el.toggle();
        });
        $('.bw-cancel-policy__close', payment_el).on('click', function(e) {
            e.preventDefault();
            policy_link.addClass('bw-underline');
            policy_el.hide();
        })
        setup_tc(settings, tv_data);
        setup_form(form_el);
        setup_nav(widget_el, STEPS.payment.name);
    }

    function render_page_gc(payment_json_config) {
        hide_loading(widget_el, true);

        let child_count = Number(opts.child) + Number(opts.toddler);
        const prices = {
            amount_only: Number(opts.amount_only ? opts.amount_only : 0),
            amount_only_taxes: Number(opts.amount_only_taxes ? opts.amount_only_taxes : 0),
            amount_only_fees: Number(opts.amount_only_fees ? opts.amount_only_fees : 0)
        };
        const total = format_currency((prices.amount_only + prices.amount_only_taxes + prices.amount_only_fees).toFixed(2));
        const payment_el = $(set_placeholders(PAYMENT_TEMPLATE, {
            selected_tour_params: {
                message: 'Gift certificates are valid for 5 years from date of purchase and may be used for any tours we offer.',
                prices: prices
            },
            submit_bar_total: total,
            submit_bar_link_text: 'Make Payment'
        }));
        payment_el.addClass('bw-gc');
        widget_el.closest('.booking-widget-container').addClass('bw-no-header');

        widget_el.append(payment_el);

        form_el = $('.bw-form', widget_el);
        error_el = $('.bw-form-errors', widget_el)
    
        setup_form(form_el);
        setup_close_btn(widget_el, STEPS.confirmed.name, function() {
            widget_el.closest('.booking-widget-container').removeClass('bw-no-header');
        })
        if (!prices.amount_only_taxes) {
            $('.bw-best-price').hide();
        }
    }

    function get_gift_certificate(id) {
        let d = $.Deferred();

        if (!id) {
            d.resolve(null);
            return d.promise();
        }
    
        let cached = get_cache('gc-data-' + id);
        if (cached != false) {
            d.resolve(cached);
            return d.promise();
        }

        _get_gift_certificate({
            id: id,
            on_done: function(json) {
                cache('gc-data-' + id, json.data[0]);
                d.resolve(json.data[0]);
            }
        });
    
        return d.promise();
    }

    function setup_tc(json, trip) {
        if (json.success && json.data && json.data.terms_conditions) {
            let terms_type = trip.vessel[0].vessel_type;
            if (opts.is_charter) {
                terms_type = 'charters-' + terms_type;
            }
            let tc = json.data.terms_conditions[terms_type];
            let tc_el = $('.bw-terms-conditions', widget_el);
            tc_el.append(tc);
        }
    }

    function setup_form(form_el) {
        setup_contact_form({
            form_el: form_el,
            error_el: error_el,
        })

        $('input[name="cc_exp"]', form_el).off('input').on('input', function(e) {
            const input = $(this).val();
            const numerical_input = input.replace(/\D/g, '');
                    
            if (numerical_input.length >= 2) {
                const formatted = `${numerical_input.slice(0, 2)}/${numerical_input.slice(2, 4)}`;
                $(this).val(formatted);
            }
            else {
                $(this).val(numerical_input);
            }
        });
        
        setup_submission(form_el);

        if (form_data.payment_amount <= 0) {
            disable_cc_fields(form_el);
            $('.bw-form--payment', widget_el).hide();
        }
    }

    function reset_submit_btn() {
        let submit_button_el = $('.bw-form-submit', widget_el);
        submit_button_el.val(submit_button_el.attr('data-original'));
    }

    function setup_submission(form_el) {
        let is_submitting = false;
        form_el.off('submit').on('submit', function(e) {
            e.preventDefault();
            if (is_submitting) {
                return false;
            }
            is_submitting = true;

            if (opts.on_book) {
                opts.on_book();
            }
            let errors = {};
            let cc_name = $('input[name="cc_name"]', form_el).val();

            if (opts.handle_gc) {
                const amount_only = Number(opts.amount_only);
                const amount_only_taxes = opts.amount_only_taxes ? Number(opts.amount_only_taxes) : 0;
                const amount_only_fees = opts.amount_only_fees ? Number(opts.amount_only_fees) : 0;
                const name_parts = split_full_name(cc_name);
    
                form_data.reservation_type = 'Gift';
                form_data.first_name = [name_parts.first_name];
                form_data.last_name = [name_parts.last_name];
                form_data.contact_price_base = [amount_only];
                form_data.contact_price_tax = [amount_only_taxes];
                form_data.contact_price_fees = [amount_only_fees];
                form_data.contact_id = [''];
                form_data.passenger_id = [''];
                form_data.is_child = ['off'];
                form_data.food_preference = [''];
                form_data.payment_amount = Number((amount_only + amount_only_taxes + amount_only_fees).toFixed(2));

                validate_contact_form(form_el, form_data, errors);

                if (errors.email) {
                    errors.email_confirm = errors.email;
                }
                else if (errors.email_confirm) {
                    errors.email = errors.email_confirm;
                }
                if (errors.phone) {
                    errors.phone_confirm = errors.phone;
                }
                else if (errors.phone_confirm) {
                    errors.phone = errors.phone_confirm;
                }

                if (!$.isEmptyObject(errors)) {
                    show_error(error_el, errors);
                    is_submitting = false;
                    return;
                }
            }

            validate_contact_form(form_el, form_data, errors);

            form_data.email = $('input[name="email"]', form_el).val();
            form_data.phone = $('input[name="phone"]', form_el).val();

            $('input[type="submit"]', form_el).val('PROCESSING...');
            $('.bw-mobile-submit-bar__button', form_el).val('PROCESSING...');

            if (form_data.payment_amount > 0) {
                $('#cardholder_name', form_el).val(cc_name);

                let cc_exp = $('#cc_exp', form_el).val().split('/');
                if (cc_exp.length != 2) {
                    errors.cc_exp = 'Please enter a valid expiration date in the format MM/YY';
                }
                if ($('#cvv_code', form_el).val().length == 0) {
                    errors.cc_cvv = 'Please enter a valid cvv number.';
                }
                if ($('#cc_name', form_el).val().length == 0) {
                    errors.cc_name = 'Please enter the name on the card.';
                }
                if ($('#cc_zip', form_el).val().length == 0) {
                    errors.cc_zip = 'Please enter the payment zip code of the card.';
                }
                if ($('#cc_number', form_el).val().length == 0) {
                    errors.cc_number = 'Please enter the number the card.';
                }
                if (!$.isEmptyObject(errors)) {
                    show_error(error_el, errors);
                    is_submitting = false;
                    reset_submit_btn();
                    return false;
                }
                $('#exp_month', form_el).val(cc_exp[0]);
                $('#exp_year', form_el).val(cc_exp[1]);
            }
            
            if (!$.isEmptyObject(errors)) {
                show_error(error_el, errors);
                is_submitting = false;
                return;
            }
            
            if (!form_data) {
                is_submitting = false;
                reset_submit_btn();
                return false;
            }

            error_el.hide();
            clear_errors();
            hide_payment_error_msg();
            show_loading(widget_el);

            if (form_data.full_name) {
                form_data.first_name = [];
                form_data.last_name = [];
                for(let i = 0; i < form_data.full_name.length; i++) {
                    const {first_name, last_name} = split_full_name(form_data.full_name[i]);
                    form_data.first_name[i] = first_name;
                    form_data.last_name[i] = last_name;
                }
            }
            
            let form_data_copy = $.extend({}, form_data);
            process_res({
                form_data: $.extend({}, form_data, charter_adjustments(form_data)),
                on_success: function(processed_res_json) {
                    process_payment({
                        form_el: form_el,
                        on_done: function(payment_json, res_json) {
                            hide_loading(widget_el);
                            is_submitting = false;
                            reset_submit_btn();

                            if (payment_json.success == false) {
                                if (payment_json.errors) {
                                    show_error(error_el, payment_json.errors, true);
                                }
                                else {
                                    show_error(error_el, 'An unknown error occurred', true);
                                }
                                if (res_json.success && res_json.data) {
                                    update_form_data();
                                }
                                return;
                            }
                            if (res_json.success == false) {
                                let res_id = form_data.reservation_id;
                                let error_msg = PAYMENT_ERROR_RES_SUCCESS + res_id + '.<br />';
                                let errors = [error_msg];
                                if (res_json.errors) {
                                    errors.concat(res_json.errors);
                                }
                                show_error(error_el, errors, true);
                                return;
                            }
                            const txn_info = setup_confirmation_info(res_json.data, payment_json.data, form_data);
                            bw_events.on_success($.extend({}, txn_info, {prices: prices}));
                        },
                        on_payment_fail: function(transaction_json) {
                            hide_loading(widget_el);
                            is_submitting = false;
                            reset_submit_btn();

                            let errors = {};
                            if (transaction_json && !transaction_json.success) {
                                if (transaction_json.details) {
                                    for (let error of transaction_json.details) {
                                        let key = error.key;
                                        if (error.key == 'exp_year' || error.key == 'exp_month') {
                                            key = 'cc_exp';
                                        }
                                        if (error.key == 'cvv_code') {
                                            key = 'cc_cvv';
                                        }
                                        else if (error.key == '') {
                                            // general fail
                                            errors.cc_name = true;
                                            errors.cc_number = true;
                                            errors.cc_exp = true;
                                            errors.cc_cvv = true;
                                            errors.cc_phone = true;
                                            errors.cc_zip = true;
                                            show_payment_error_msg();
                                        }
                                        errors[key] = true;
                                    }
                                }
                                else if (transaction_json.errors) {
                                    errors.cc_name = true;
                                    errors.cc_number = true;
                                    errors.cc_exp = true;
                                    errors.cc_cvv = true;
                                    errors.cc_phone = true;
                                    errors.cc_zip = true;
                                    show_payment_error_msg();
                                }
                            }
            
                            show_error(error_el, errors, true);
                            if (processed_res_json.success && processed_res_json.data) {
                                update_form_data();
                            }
                        }
                    });
                    bw_events.on_payment_submit($.extend({}, form_data_copy, {prices: prices}));
                },
                on_failure: function(json) {
                    hide_loading(widget_el);
                    is_submitting = false;
                    reset_submit_btn();
                    
                    if (json.errors) {
                        show_error(error_el, json.errors, true);
                    }
                    else {
                        show_error(error_el, 'An unknown error occurred', true);
                    }
                }
            })
        });
    }

    function process_res(options) {
        let reservation_id = form_data.reservation_id;
        let gift_certificate_id = form_data.gift_certificate_id;
        delete form_data.reservation_id;
        delete form_data.gift_certificate_id;
        let params = {
            data: options.form_data,
            on_done: function(json) {
                if (json.success == false) {
                    options.on_failure(json);
                    return;
                }
    
                if (reservation_id == '') {
                    form_data['reservation_id'] = json.data.id;
                }
    
                if (options.on_success) {
                    if (json.data) {
                        form_data.reservation_id = parseInt(json.data.id) || 0;
                        res_num = json.data.number;
                    }
                    options.on_success(json);
                }
            }
        }
        if ((typeof reservation_id !== 'undefined') && reservation_id != '') {
            params.id = reservation_id;
            patch_res(params);
        }
        else if ((typeof gift_certificate_id !== 'undefined') && gift_certificate_id != '') {
            params.id = gift_certificate_id;
            patch_res(params);
        }
        else {
            post_res(params);
        }
    }

    function process_payment(options) {
        let update_res_params = {
            status: 'Active'
        };

        // no payment, just patch the res
        if (form_data.payment_amount <= 0) {
            patch_res({
                id: form_data.reservation_id,
                data: update_res_params,
                on_done: function(json) { 
                    options.on_done({success:true}, json);
                }
            });

            return;
        }

        $.when(process_cc_payment(options.form_el)).then(function(transaction_json) {
            if (transaction_json.success === true) {
                // update res status
                patch_res({
                    id: form_data.reservation_id,
                    data: update_res_params,
                    on_done: function(json) { 
                        options.on_done(transaction_json, json);
                    }
                });
            }
            else {
                // debug
                if (process.env.NODE_ENV !== 'production') {
                    console.error(transaction_json);
                }
                options.on_payment_fail(transaction_json);
            }
        });
    }

    function process_cc_payment(form_el) {
        // let bypass_payment = true;
        var d = $.Deferred();

        let payment_config = get_cache('payment_config');
        cc_get_token(payment_config, form_el, function(response) {
            let result = cc_eval_response(response);
            if (result.success == true) {
                let transaction_data = result.opaque_data;
                transaction_data.reservation_id = form_data.reservation_id;
                transaction_data.payment_amount = form_data.payment_amount;
                transaction_data.cardholder_name = $('#cardholder_name', options.form_el).val();
                transaction_data.zip_postal_code = $('input[name="cc_zip"]', options.form_el).val();
                transaction_data.payment_method = 'cc';

                transaction_post({
                    data: transaction_data,

                    on_success: function(json) {
                        d.resolve(json);
                    },
                    on_fail: function(json) {
                        d.resolve({success: false, errors: json.errors});
                    }
                });
            }
            else {
                d.resolve({success: false, errors: result.errors.map((e) => e.text), details: result.errors});
            }
        });

        return d.promise();
    }

    function update_form_data() {
        show_loading(widget_el);

        // only get passengers if we have a res id.
        let res_id = form_data.reservation_id;
        if ((typeof res_id === 'undefined') || res_id == '') {
            hide_loading(widget_el);
            return;
        }

        _get_passengers({
            fields: 'id,contact.id',
            expand: 'reservation,contact',
            include: false,
            filters: { raw: 'reservation.id.eq(' + res_id + '),status.in(Active,Pending)'},
            order: 'passenger.id',
            on_done: function(json) {
                // populate form data
                if (json.success) {
                    let data = json.data;
                    for (let idx = 0; idx < data.length; idx++) {
                        form_data.passenger_id[idx] = data[idx].id;
                        form_data.contact_id[idx] = data[idx].contact[0].id ? data[idx].contact[0].id : '';
                    }
                }
                hide_loading(widget_el);
            }
        });
    }

    function disable_cc_fields(el) {
        $('input[name^="cc_"], select[name^="cc_"]', el).attr('disabled', 'disabled');
    }

    function setup_confirmation_info(res_data, payment_data, form_data) {
        let tour = get_cache('tour-' + form_data.tour_id);
        let allowed_food_preferences = get_cache('booking_settings-food_preferences');
        let food_prefs = {} 
        for (let i in allowed_food_preferences) {
            food_prefs[allowed_food_preferences[i]['value']] = 0;
        }
        let passenger_counts = {
            adult: 0,
            child: 0,
            food_prefs: food_prefs
        };

        let total_base = false;
        if (!form_data.amount_only) {
            for (let i = 0; i < form_data.contact_id.length; i++) {
                if (form_data.food_preference[i] in passenger_counts.food_prefs) {
                    passenger_counts.food_prefs[form_data.food_preference[i]]++;
                }
                if (form_data.is_child[i] == 'off') {
                    passenger_counts.adult++;
                }
                else {
                    passenger_counts.child++;
                }
            }
        }
        else {
            // gc has no passenger counts, just amounts.
            total_base = form_data.amount_only;
        }

        let payment_amount = form_data.payment_amount;
        let name = form_data.first_name[0] + ' ' + form_data.last_name[0];
        if (typeof payment_data !== 'undefined' && payment_data && payment_data.last_4_cc) {
            name = $('input[name="cc_first_name"]', form_el).val();
            name += $('input[name="cc_last_name"]', form_el).val();
        }

        let info = {
            name: name,
            payment_amount: isNaN(payment_amount) ? '0.00' : Number(total_base).toFixed(2),
            email: form_data.email,
            first_name: form_data.first_name,
            last_name: form_data.last_name,
            food_preference: form_data.food_preference,
            is_child: form_data.is_child,

            reservation_id: res_data.id,
            reservation_number: res_data.number,
            tour_id: tour.id,
            tour_name: tour.name,
            trip_id: form_data.tour_vessel_id,
            prices: prices,

            adult: passenger_counts.adult,
            child: passenger_counts.child,

            is_gift: form_data.is_gift ? form_data.is_gift : 0
        };
        if (typeof payment_data !== 'undefined' && payment_data) {
            if (payment_data.last_4_cc) {
                info.cc_last4 = payment_data.last_4_cc;
            }

            // override payment amount with what we got from the transaction
            if (payment_data.amount) {
                info.payment_amount = Number(payment_data.amount).toFixed(2);
            }
        }

        return info;
    }

    function show_payment_error_msg() {
        $('.payment-error-message').text(PAYMENT_ERROR_GENERIC).show();
    }

    function hide_payment_error_msg() {
        $('.payment-error-message').hide();
    }
}

export const has_required_params = (params) => {
    return !!(params.trip_id && params.trip_id != 0
           && params.tour_id && params.tour_id != 0 
           && params.contact_id && Array.isArray(params.contact_id) && params.contact_id.length > 0
           && params.adult && params.adult != 0
    );
}

function charter_adjustments(form_data) {
    if (!form_data.is_charter) {
        return {};
    }
    const new_form_data = {};
    const passenger_count = form_data.first_name.length;
    const contact_method = form_data.contact_method != '' ? form_data.contact_method + ' is the preferred contact method.': '';
    new_form_data.reservation_note = form_data.reservation_note + "| " + passenger_count + " Passengers | "  + contact_method;
    
    return new_form_data;
}