import { init as setup_modal, show as show_modal } from 'ui/modal';

import { EXIT_TEMPLATE } from 'booking-widget/v2/templates/exit-confirm';

export const show = (options) => {
    let el = $('#bw-exit-confirm');
    if (el.length == 0) {
        setup_modal({
            html: EXIT_TEMPLATE
        });
        el = $('#bw-exit-confirm');
        let on_exit = options.on_exit ? options.on_exit : function() {};
        $('.bw-exit-button', el).on('click', function(e) {
            e.preventDefault();

            if (on_exit) {
                on_exit();
            }
        });
    }
    show_modal(el);
}