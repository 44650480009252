import { init as init_phone_input, is_possible_number, get_number } from 'booking-widget/v2/widgets/phone-input';
import { force_default_name_capitalization_format } from 'utils/strings';
import { update_data_targets } from 'ui/data';

export const setup_form = (els) => {
    let form_el = els.form_el;

    init_phone_input('.intltelinput');

    $('input[name="contact_first_name"], input[name="contact_last_name"]').on('change', function() {
        $(this).val(force_default_name_capitalization_format($(this).val()));
    });

    let opt_in_hidden_el = $('input[name="opt_in"]', form_el);
    $('#opt-in-sms').off('change').on('change', function() {
        opt_in_hidden_el.val($(this).is(':checked') ? 1 : 0);
    });

}

// alters form_data and errors
export const validate_form = (form_el, form_data, errors) => {
    const phone_el = $('input[name="phone"]', form_el);
    if (!is_possible_number(phone_el)) {
        errors.phone = 'Your phone number does not look like a valid number.';
    }
    else { 
        // get_number expects a valid number
        form_data.phone = get_number(phone_el);
    }
    
    const email_el = $('input[name="email"]', form_el);
    const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    if (!regex.test(email_el.val())) {
        errors.email = 'Your email does not look like a valid email address.';
    }
}