import Pikaday from 'pikaday';
import { 
    local_to_tz_display,
    convertISOtoDate,
    to_system_date
} from 'utils/date';

// the datepicker does not have timezones, but we need to display calendar in HST 
// (like "today" in Japan is not the same day in Hawaii)
// Whenever dates go into daterangepicker it needs to be converted from local to HST.
// Whenever dates come out of daterangepicker it needs to be converted from HST to local.
export const init = (options) => {
    if (!options) {
        options = {};
    }
    if (options.datepicker_el.hasClass('pika-initialized')) {
        return;
    }

    let datepicker_el = options.datepicker_el;
    let utc_offset = options.utc_offset;
    let margin_top = options.margin_top ? options.margin_top : '60px';
    let show_single = $(window).height() <= 700 || $(window).width() <= 768;

    // pikaday accepts strings, but js does crazy things converting string to date.
    // https://stackoverflow.com/a/31732581/999816
    const today = new Date();
    let default_date = new Date();
    let min_date = today;
    // if after all tours are done (3pm HST), start on next day
    if (local_to_tz_display(today, utc_offset).getHours() > 15) {
        min_date.setDate(today.getDate() + 1);
    }
    if (!options.start_time) {
        default_date = local_to_tz_display(min_date, utc_offset);
    }
    else {
        default_date = convertISOtoDate(options.start_time + ' 00:00:00');
    }
    let num_months = options.numberOfMonths ? options.numberOfMonths : (show_single ? 1 : 2);
    let pikaday = new Pikaday({ 
        // bound: false, // keep it open
        field: datepicker_el[0],    
        minDate: local_to_tz_display(min_date, utc_offset),
        defaultDate: default_date,
        setDefaultDate: !!options.start_time,
        numberOfMonths: num_months,
        events: options.events ? options.events : [],
        theme: (options.theme ? options.theme : '') + ' pikaday-months-' + num_months,
        i18n: {
            previousMonth : 'Previous Month',
            nextMonth     : 'Next Month',
            months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
            weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
            weekdaysShort : ['Su','Mo','Tu','We','Th','Fr','Sa']
        },
        toString: function(dt, format) {
            if (format == 'YYYY-MM-DD') {
                return to_system_date(dt);
            }
            else {
                return dt.toDateString();
            }
        },
        onSelect: function(date) {
            datepicker_el.attr('data-touched', 1);
            if (options.onSelect) {
                options.onSelect(date);
            }
        },
        onOpen: function() {
            if (options.beforeOnOpen) {
                options.beforeOnOpen();
            }
            const pikaday_el = $(this.el);
            const pikaday_offset = pikaday_el.offset();
            const control_top = datepicker_el.offset().top;
            if (control_top < pikaday_offset.top) {
                pikaday_el.css({marginTop: margin_top});
            }
            else {
                let topOverride = getComputedStyle(pikaday_el[0]).getPropertyValue('--bw-bwdp-top-override');
                
                let adjustment = 34;
                if (topOverride) {
                    topOverride = topOverride.replace(/"/g, '');
                    let newAdj = parseInt(topOverride);
                    if (!isNaN(newAdj)) {
                        adjustment = newAdj;
                    }
                }
                pikaday_el.css({ top: (control_top - pikaday_el.outerHeight(true) - adjustment) + 'px' });
            }

            const screen_top = $(document).scrollTop();
            const adjustments = 25;
            if ((pikaday_offset.top - adjustments) < screen_top) {
                setTimeout(function() {
                    pikaday_el.css({top: screen_top + adjustments});
                }, 300);
            }

            // fix negative left due to margins not being accounted for
            if (pikaday_offset.left < 0) {
                $(this.el).css({
                    right: '1%',
                    left: 'unset'
                });
            }

            if (options.onOpen) {
                options.onOpen();
            }
        },
        onClose: function() {
            if (options.onClose) {
                options.onClose();
            }
        }
    });

    let fn_hide = pikaday.hide;
    if (options.keep_open) {
        pikaday.hide = function() {};
        pikaday.hide_force = fn_hide;
    }
    datepicker_el.addClass('pika-initialized');

    if (options.onInit) {
        options.onInit(pikaday, options);
    }

    return pikaday;
}

export const get_datestring = (pikaday, format) => {
    return pikaday.toString(format ? format : 'YYYY-MM-DD');
}

export const destroy = (pikaday) => {
    pikaday.destroy()
}