
export const EXIT_TEMPLATE = `
    <section class="bw-modal" id="bw-exit-confirm" aria-hidden="true">
        <div class="bw-modal__overlay" tabindex="-1" data-ts-modal-close>
            <div class="bw-modal__container" role="dialog" aria-modal="true">
                <main class="bw-modal__content bw-text-center">
                    <div class="bw-modal-icon">!</div>
                    <h1 class="bw-m--0">Are you sure you want to exit booking?</h1>
                    <p class="bw-my--30">All your information will be lost</p>
                    <a href="#" class="bw-button bw-continue-button bw-mb--30" data-ts-modal-close>Continue Booking</a>
                    <a href="#" class="bw-button bw-exit-button" data-ts-modal-close>Exit Booking</a>
                </main>
            </div>
        </div>
    </section>
`;