/** @module data/tour-vessel */

import { get as get_data, patch as patch_data, delete_data } from 'data/data';
import { get as get_cache, cache } from 'utils/cache';
import log from 'utils/log';
import { 
    to_system_date, 
    is_valid_date,
    convertISOtoDate,
    is_valid_timestamp
} from 'utils/date';

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let default_fields = 'id,tour_id,vessel_id,destination_location_name,destination_location_id,is_alternate_destination,start_time,' + 
                         'checkin_time,end_time,status,progress_state,is_boarding_pass_ready,is_self_checkin_enabled,is_active,passenger_count,passenger_count_adult,passenger_count_child,' + 
                         'agency_passenger_count,internal_passenger_count,block_passenger_count,is_charter,is_locked,' +
                         'meal_count_vegetarian,meal_count_vegan,meal_count_pescatarian,meal_count_gluten,meal_count_dairy,meal_count_issues,' +
                         'waitlist_count,capacity_available,block_count,checkin_count,uncheckin_count,' + 
                         'vessel.id,vessel.name,vessel.capacity_max,vessel.capacity_max_additional,vessel.vessel_type,vessel.in_service,vessel.display_order,' +
                         'tour.name,tour.id,tour.color,tour.abbrv,tour.display_order,' +
                         'user.id,user.first_name,user.last_name,' +
                         'location.name,notes';

    let defaults = {
        endpoint: 'tour_vessel',
        cache_name_prefix: 'tour-vessel-detail-',
        fields: default_fields,
        filters: 'status.neq(Deleted)',
        expand: 'tour,agency_availability',
        order: 'vessel.display_order,start_time'
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const patch = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'tour_vessel'
    }
    
    return patch_data($.extend(true, {}, defaults, options));
}

export const delete_bulk = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'tour_vessel',
        id: 0,
        data: {
            tour_id: options.tour_id,
            from_date: options.from_date
        }
    }
    
    return delete_data($.extend(true, {}, defaults, options));
}

export const get_for_booking = (options) => {
    if (typeof options === 'undefined') {
        options = {};
    }
    let opts = $.extend({
        id: false,
        tour_id: false,
        trip_id: false,
        start_timestamp_between: false,
        // minimum should be 3 so results are for 3 days before and after start_time
        // but the higher the number, the more available dates can be suggested
        days_offset: 7, 
        time_of_day: false,
        pax_count: false,
        has_toddlers: false,
        search_text: false,
        modal: false,
        capacity_max: false,
        tour_tag_id: false,
        ne_tour_id: false,
        charters: false,
        order: 'start_time'
    }, options);

    let filters = 'status.eq(Active),tour.tour_url.neq(null)';
    const min_days_offset = 3;

    if (!opts.start_time && !opts.id && (!opts.start_timestamp_between || opts.start_timestamp_between.length != 2 || !is_valid_timestamp(opts.start_timestamp_between[0]) || !is_valid_timestamp(opts.start_timestamp_between[1]))) {
        opts.start_timestamp_between = [Date.now(), Date.now()];
    }

    if (opts.start_timestamp_between != false) {
        filters += ',start_timestamp.gte(' + Math.floor(opts.start_timestamp_between[0]/1000) + ')';
        filters += ',start_timestamp.lte(' + Math.floor(opts.start_timestamp_between[1]/1000) + ')';
    }
    else {
        // require id or date
        if (!opts.id && !opts.start_time) {
            opts.start_time = to_system_date(new Date());
        }
        if (opts.start_time) {
            let start_dt = convertISOtoDate(opts.start_time + ' 00:00:00');
            let end_dt = convertISOtoDate(opts.start_time + ' 00:00:00');
            
            if (!is_valid_date(start_dt) || !is_valid_date(end_dt)) {
                start_dt = new Date();
                end_dt = new Date();
            }
            if (opts.days_offset) {
                start_dt.setDate(start_dt.getDate() - opts.days_offset);
                end_dt.setDate(end_dt.getDate() + opts.days_offset);
            }
            filters += ',start_time.gte(' + to_system_date(start_dt) + ')';
            filters += ',start_time.lte(' + to_system_date(end_dt) + ' 23:59:59)';
        }
    }
    if (opts.pax_count != false) {
        filters += ',seats_available.gte(' + opts.pax_count + ')';
    }
    if (opts.charters !== false) {
        filters += ',charter_available.eq(1)';
    }
    if (opts.capacity_max != false) {
        filters += ',vessel.capacity_max.gte(' + opts.capacity_max + ')';
    }
    if (opts.has_toddlers == true) {
        filters += ',vessel.toddlers_allowed.eq(1)';
    }
    if (opts.time_of_day != false) {
        filters += ',time_of_day.eq(' + opts.time_of_day + ')'
    }
    if (opts.tour_id != false) {
        filters += ',tour_id.eq(' + opts.tour_id + ')';
    }
    if (opts.ne_tour_id != false) {
        filters += ',tour_id.neq(' + opts.ne_tour_id + ')';
    }
    if (opts.trip_id != false) {
        filters += ',trip_id.eq(' + opts.tour_id + ')';
    }
    if (opts.id != false) {
        filters += ',id.eq(' + opts.id + ')';
    }
    // only find non-charter trips if not looking for a specific trip.
    if (!opts.hasOwnProperty('trip_id')) {
        filters += ',is_charter.eq(0)';
    }

    if (opts.booking_priority_lt) {
        filters += 'vessel.booking_priority.lt(' + opts.booking_priority_lt + ')';
    }

    if (opts.booking_priority_gt) {
        filters += 'vessel.booking_priority.gt(' + opts.booking_priority_gt + ')';
    }

    if (opts.search_text !== false) {
        if (opts.search_text.length > 2) {
            filters += ',phrases.like(' + opts.search_text + ')';
        }
    }

    let expand = 'tour';

    if (opts.tour_tag_id !== false) {
        filters += ',tour_tag.tag_id.eq(' + opts.tour_tag_id + ')';
        expand += ',tour_tag';
    }
    let defaults = {
        fields: 'id,start_time,end_time,seats_available,phrases,tour_id,'
            + 'adult_price_base,child_price_base,cutoff_timestamp,start_timestamp,'
            + 'vessel.name,vessel.vessel_type,vessel.booking_priority,vessel.capacity_max,vessel.toddlers_allowed,vessel.pregnant_allowed,location.name,location.map_uri,location.directions_uri',
        expand: expand,
        filters: filters,
        order: opts.order
    };
    return get($.extend(true, {}, defaults, options));
}

// get by date range and cache by date range if no raw filters
export const get_by_date = (options) => {
    if (options == null) {
        options = {};
    }
    options.start_date = options.start_date || null;
    options.end_date = options.end_date || null;
    options.on_done = options.on_done || (function() {});
    options.force = options.force || false;
    // use options.cache_name_prefix = null to not cache.
    // options.has_filters = options.filters.hasOwnProperty('raw');

    // we're going to reconstitute the cached data from tour-vessel-ids because
    // otherwise we have to deal with udpating  the date-cached data
    // during every stream update

    // if the data is cached, get it and done
    // unless we've provided filters, in which case don't cache at all
    // this'll just be a list of trip ids
    // and it'll return false if ANY of those are missing from cache

    // using our own cache, not the default one in data/data.
    let cache_name = 'tour-vessel-' + options.start_date.toDateSystem() + options.end_date.toDateSystem();

    if (!options.force) {
        let json = build_cache_from_date(get_cache(cache_name));

        if (json != false) {
            options.on_done(json);
            return null; // needed if used in jquery when/then
        }        
    }
    
    log('getting tour vessel data for ' + options.start_date);

    let filters = 'start_time.gte(' + options.start_date.toDateSystem() + '),' +
                  'start_time.lte(' + options.end_date.addDays(1).toDateSystem() + '),' +
                  'status.neq(Deleted)'
    ;
    
    let options_on_done = options.on_done;

    delete options.on_done;

    let defaults = {
        cache_name_prefix: 'tour-vessel-detail-',
        filters: filters,
        on_done: function(json) {
            // cache the whole date
            // if there's no filters
            if (!options.has_filters) {
                let ids = [];
                // #TODO this is caching a bunch of data that's duplicative, de-dupe it
                for (let row of json.data) {
                    ids.push(row.id);
                }
                cache('tour-vessel-' + options.start_date.toDateSystem() + options.end_date.toDateSystem(), ids);
            }
            
            options_on_done(json);
        }
    }
    return get($.extend(true, defaults, options));
}

export const submit_manifest = (options) => {
    if (options == null) {
        return false;
    }

    let options_on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});

    delete options.on_done;

    let data = {}
    if (options.status_reason) {
        data = {status_reason: options.status_reason};
    }

    let defaults = {
        endpoint: 'tour_vessel/' + options.tour_vessel_id + '/submit_manifest',
        data: {},
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }
    return get_data($.extend(true, {}, defaults, options));
}

export const check_availability = (options) => {
    let options_on_done = options.on_done || (function() {});
    
    let defaults = {
        endpoint: 'tour_vessel/' + options.id + '/check_availability',
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }
    return get_data($.extend(true, {}, defaults, options));
}

export const get_passenger_logs = (options) => {
    let defaults = {
        endpoint: 'tour_vessel/' + options.tour_vessel_id + '/passenger_logs/',
        limit: 1000
    }

    return get_data($.extend(true, {}, defaults, options));
}


function build_cache_from_date(date_data) {
    if (!date_data) {
        return false;
    }

    let ids = Object.values(date_data);
    if (ids.length == 0) {
        return false;
    }

    let return_data = { success: true, cached: true, data: []};

    // each row is just an id
    for (let id of ids) {
        let row = get_cache('tour-vessel-detail-' + id);
        if (!row) {
            return false;
        }
        return_data.data.push(row);
    }

    return return_data;
}

export class PassengerCount {
    constructor() {
        this.tour_vessel_id = 0;
        this.adult = {
            total: 0,
            nci: 0,
            checkedin: 0,
            boarded: 0
        };
        this.child = {
            total: 0,
            nci: 0,
            checkedin: 0,
            boarded: 0
        };
        this.crew = {
            total: 0,
            boarded: 0
        };
        this.ridealong = {
            total: 0,
            boarded: 0
        },
        this.cancelled_total = 0,
        this.cancelled_other = 0,
        this.cancelled_at_dock = 0,
        this.meal_standard = 0,
        this.meal_veg = 0;
        this.meal_special = 0;
    }
}

export const can_move_to_trip = (origin_tour_vessel, dest_tour_vessel, rules) => {
    if (origin_tour_vessel.id == dest_tour_vessel.id) {
        return false;
    }

    if (rules.pricing) {
        if (rules.pricing == 'only_upgrades') {
            if ((Number(dest_tour_vessel.adult_price_base)).Round(2) < (Number(origin_tour_vessel.adult_price_base)).Round(2)) {
                return false;
            }
        }
    }
    if (rules.trip_time) {
        if (rules.trip_time == 'same') {
            if (origin_tour_vessel.start_time != dest_tour_vessel.start_time) {
                return false;
            }
        }
    }

    if (rules.vessel_type) {
        if (rules.vessel_type == 'same') {
            if (origin_tour_vessel.vessel[0].vessel_type != dest_tour_vessel.vessel[0].vessel_type) {
                return false;
            }
        }
    }

    if (rules.tour) {
        if (rules.tour == 'same') {
            if (origin_tour_vessel.tour[0].id != dest_tour_vessel.tour[0].id) {
                return false;
            }
        }
    }
    if (rules.status) {
        if (rules.status == 'only_to_active') {
            if (dest_tour_vessel.status != 'Active') {
                return false;
            }
        }
    }

    return true;
}

// sort key consists of key_parts
export const get_sort_key = (tour_vessel_data, key_parts) => {
    const default_parts = ['vessel_type', 'time', 'tour_order', 'booking_priority', 'tour_id'];
    key_parts = key_parts ? key_parts : default_parts;

    let key = [];
    for (const part of key_parts) {
        switch(part) {
            case 'vessel_type':
                key.push(tour_vessel_data.vessel[0].vessel_type == 'raft' ? 1 : 0);
                break;

            case 'time':
                let start_date = Date.convertISOtoDate(tour_vessel_data.start_time);
                let time = ((start_date.getHours() * 100) + start_date.getMinutes()) + '';
                key.push(time.padStart(4, '0'));
                break;

            case 'tour_order':
                let display_order = tour_vessel_data.tour[0].display_order;
                display_order = String(display_order != null && display_order !== '' ? display_order : '0');
                key.push(display_order.padStart(4, '0'));
                break;
            
            case 'booking_priority':
                let booking_priority = tour_vessel_data.vessel[0].booking_priority;
                booking_priority = String(booking_priority != null && booking_priority !== '' ? booking_priority : '0');
                key.push(booking_priority.padStart(4, '0'));
                break;
            case 'tour_id':
                key.push(tour_vessel_data.tour_id.padStart(4, '0'));
                break;
        }
    }

    return key.join('-');
}