import { get_for_booking as _get_trips } from 'data/tour-vessel';
import { get_tours } from 'booking-widget/v2/common/data';
import { get as get_cache, cache } from 'utils/cache';
import { 
    filter_data, 
    tour_highlights, 
    set_placeholders,
    hide_loading,
    show_loading,
} from 'booking-widget/v2/common/util';
import { 
    local_to_tz_display,
    convertISOtoDate, 
    to_system_date, 
} from 'utils/date';
import { strip_tags, esc_html } from 'utils/strings';
import { 
    show_loading as _show_loading, 
    hide_loading as _hide_loading, 
    observe_items,
} from 'ui/visibility';
import {
    FS_LOADING_TEMPLATE
} from 'booking-widget/v2/templates/shared';
import {
    CATEGORY_TEMPLATE,
    CARD_TEMPLATE,
    TOURS_TEMPLATE,
    UNAVAILABLE_TEMPLATE,
    NO_RESULTS_TEMPLATE,
    NO_RESULTS_TEMPLATE_CUSTOM
} from 'booking-widget/v2/templates/tours';

import { init as init_guestpicker, destroy_guestpicker } from 'booking-widget/v2/widgets/guestpicker';
import Cookies from 'js-cookie'

import { 
    set_datepicker_display as _set_datepicker_display
} from 'booking-widget/v2/widgets/datepicker';
import DateCarousel from 'booking-widget/v2/widgets/date-carousel';
import { setup as setup_nav } from 'booking-widget/v2/widgets/nav';
import { STEPS, show_fullscreen_modal, navigate, load_prev_step } from 'booking-widget/v2/bw';

const BOOKING_STATUS_SOLD_OUT = 'sold-out';
const BOOKING_STATUS_AFTER_CUTOFF = 'cutoff';
const BOOKING_STATUS_AVAILABLE = 'available';
const BOOKING_STATUS_AGE = 'age-restricted';


export const load = (options, bw_events) => {
    let io = null; // Intersection Observer

    const hst_now_dt = local_to_tz_display(new Date(), options.utc_offset);
    const tomorrow_dt = new Date(hst_now_dt.toDateString());
    tomorrow_dt.setDate(tomorrow_dt.getDate() + 1);
    const end = new Date(tomorrow_dt.toDateString());
    end.setDate(end.getDate() + 7);
    
    let opts = $.extend(true, {
        filter_tour_id: 0,
        start_time: to_system_date(tomorrow_dt),
        adult: 2,
        child: 0,
        toddler: 0,
        is_tour_specific: false,
        search_tag: false,
        is_charter: false,
        vessel_type: false
    }, (options || {}));

    if (!has_required_params(opts)) {
        load_prev_step(opts)
        return;
    }

    let widget_el = show_fullscreen_modal(opts.context_el);

    widget_el.append(set_placeholders(TOURS_TEMPLATE, {
        title: (opts.search_tag !== false) ? esc_html(opts.search_tag.name) + ' Tours' : 'Available Tours'
    }));

    setup_nav(widget_el, STEPS.tours.name);
    let date_carousel = new DateCarousel($.extend(true, {}, opts, {
        on_date_select: function() { get_trips(); }
    }));

    let fetching = {};    // track if AJAX fetch of availability is already in progress
    let is_tour_specific = !!opts.is_tour_specific;
    let results_el = $('.bw-results', widget_el);
    let is_initial = true;

    init_guestpicker({
        adult: opts.adult,
        child: opts.child,
        toddler: opts.toddler,
        is_raft_only: opts.is_raft_only,
        on_hidden: function(popup_el, guest_counts) {
            let search_params = get_search_params();
            if (search_params.adult != guest_counts.adult || 
                search_params.child != guest_counts.child || 
                search_params.toddler != guest_counts.toddler) {
                    destroy_guestpicker();
                    bw_events.on_reload($.extend({}, search_params, guest_counts));
            }
        }
    });

    bw_events.on_loaded(opts);

    get_trips();

    function get_search_params() {
        let search_params = {
            start_time: date_carousel.curr_date,
            adult: opts.adult,
            child: opts.child,
            toddler: opts.toddler,
            tour_id: opts.is_tour_specific ? opts.filter_tour_id : 0,
            total_pax: Number(opts.adult) + Number(opts.child) + Number(opts.toddler) // cannot be named pax_count or it'll be in the search
        };

        if (opts.vessel_type) {
            search_params.vessel_type = opts.vessel_type;
        }
        if (opts.is_charter) {
            search_params.is_charter = (opts.is_charter && opts.is_charter == true) ? 1 : 0;
        }
        search_params.amount_only = null;
        search_params.amount_only_taxes = null;
        
        return search_params;
    }

    function get_trips() {
        let search_params = get_search_params();
        let start_dt = convertISOtoDate(search_params.start_time + ' 00:00:00');
        let adjusted_start_time = search_params.start_time;

        const days_offset = 7;
        let test_dt = new Date(start_dt);
        test_dt = test_dt.setDate(test_dt.getDate() - days_offset);
        if (test_dt < (new Date())) {
            start_dt = new Date();
            start_dt.setDate(start_dt.getDate() + days_offset);
            adjusted_start_time = to_system_date(start_dt);
        }

        // only get results for future dates
        let adjusted_search_params = $.extend({}, search_params, {start_time: adjusted_start_time});
        if (opts.search_tag !== false) {
            adjusted_search_params.tour_tag_id = opts.search_tag.id
        }
        // we still want the full results so we can recommend other tours
        delete adjusted_search_params.tour_id;
        let cache_key = 'booking-tours-' + JSON.stringify(adjusted_search_params);
        if ((cache_key in fetching) && fetching[cache_key]) {
            // already fetching this query.
            return;
        }

        fetching[cache_key] = true;
        let cached = get_cache(cache_key);
        if (cached != false) {
            bw_events.on_change(to_url_params(search_params));
            delete fetching[cache_key];
            render_results(cached.data, search_params);
            return;
        }
        if (!options.is_first_load) {
            show_loading(results_el, is_initial);
        }
        options.is_first_load = false;

        $.when(_get_trips(adjusted_search_params), get_tours())
            .then(function(trips_json, tours_now_cached) {
                // update url
                bw_events.on_change(to_url_params(search_params));

                
                delete fetching[cache_key];
                cache(cache_key, trips_json);
                render_results(trips_json.data, search_params);
                hide_loading(results_el, is_initial);
                is_initial = false;
            })
        ;
    }

    function render_results(data, search_params) {
        let tours = {};
        let tour_data = get_cache('tours').data;
        let lazy_selector = '.bw-tours__card__img';
        let is_tour_specific = (search_params.tour_id && search_params.tour_id != 0);

        for (let i = 0; i < tour_data.length; i++) {
            tours[tour_data[i].id] = tour_data[i];
        }

        if (io) {
            document.querySelectorAll(lazy_selector).forEach(function(element) { 
                io.unobserve(element);
            });
            io = null;
        }

        results_el.empty();
        if (typeof tour_data === 'undefined' || data.length == 0) {
            results_el.append('<h2 class="bw__results--no-tours">No tours found</h2>');
            return;
        }

        let pax_count =  {
            adult: search_params.adult,
            child: search_params.child,
            toddler: search_params.toddler,
        };
        let total_count = search_params.total_pax;
        let categorized_trips = {};
        if (opts.is_charter) {
            let only_vessel_type = false;
            if (opts.vessel_type && opts.vessel_type != '' && opts.vessel_type.indexOf(',') === -1) {
                only_vessel_type = opts.vessel_type;
            }
            let only_tour_ids = tour_data.filter(function(t) {
                if (!t.tags || t.tags == 0) {
                    return false;
                }
                return t.tags.findIndex(function(tag) { return tag.slug == 'charter' }) !== -1;
            }).map(function(tour) { return tour.id });
            
            let exclude_tour_ids = new Set();
            categorized_trips = {};
            // if not only rafts, get catamarans
            if (only_vessel_type != 'raft') {
                let catamarans = tv_data_filter(data, { 
                    only_on_start_time: search_params.start_time,
                    vessel_type: 'catamaran',
                    charter_available: true,
                    pax_count: pax_count,
                    only_available: true,
                    tour_id: only_tour_ids
                });
                categorized_trips['65\' Star Catamaran Charters'] = catamarans;
                catamarans.forEach((tv) => exclude_tour_ids.add(tv.tour_id));
            }
            // if not only catamarans, get rafts
            if (only_vessel_type != 'catamaran') {
                let rafts = tv_data_filter(data, { 
                    only_on_start_time: search_params.start_time,
                    vessel_type: 'raft',
                    charter_available: true,
                    pax_count: pax_count,
                    only_available: true,
                    tour_id: only_tour_ids
                });
                categorized_trips['24\' Rigid Hull Raft Charters'] = rafts;
                rafts.forEach((tv) => exclude_tour_ids.add(tv.tour_id));
            }
            let unavailable = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                vessel_type: only_vessel_type,
                pax_count: pax_count,
                only_unavailable: true,
                charter_available: true,
                not_tour_id: Array.from(exclude_tour_ids),
                tour_id: only_tour_ids
            });

            categorized_trips['Unavailable'] = unavailable;
        }
        else if (!is_tour_specific) {
            let morning_sails = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                ampm: 'am',
                vessel_type: 'catamaran',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            });
            let afternoon_sails = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                ampm: 'pm',
                vessel_type: 'catamaran',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            });
            let rafts = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                vessel_type: 'raft',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            });

            // get all available tour ids
            let exclude_tour_ids = new Set();
            morning_sails.forEach((tv) => exclude_tour_ids.add(tv.tour_id));
            afternoon_sails.forEach((tv) => exclude_tour_ids.add(tv.tour_id));
            rafts.forEach((tv) => exclude_tour_ids.add(tv.tour_id));
            let unavailable = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                pax_count: pax_count,
                only_unavailable: true,
                not_tour_id: Array.from(exclude_tour_ids)
            });

            categorized_trips = {
                'Morning Sails': morning_sails,
                'Afternoon Sails': afternoon_sails,
                'Rafts': rafts,
                'Unavailable': unavailable
            }
        }
        else {
            let specific_tour = tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                tour_id: search_params.tour_id,
                min_seats_available: search_params.total_pax,
                pax_count: pax_count
            });
            let has_availabilities = specific_tour.length > 0;
            if (has_availabilities) {
                let booking_status = get_booking_status(specific_tour[0], total_count, search_params.toddler);
                if (!is_booking_allowed(booking_status)) {
                    has_availabilities = false;
                }
            }

            // order by the tour's vessel_type
            let vessel_type_order = 'catamaran';
            let sample_trip = get_sample_trip(data, search_params.tour_id);
            if (sample_trip && sample_trip.vessel && sample_trip.vessel.length > 0) {
                vessel_type_order = sample_trip.vessel[0].vessel_type;
            }

            let morning_sails = has_availabilities ? [] : tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                ampm: 'am',
                vessel_type: 'catamaran',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            })
            let afternoon_sails = has_availabilities ? [] : tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                ampm: 'pm',
                vessel_type: 'catamaran',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            });
            let rafts = has_availabilities ? [] : tv_data_filter(data, { 
                only_on_start_time: search_params.start_time,
                vessel_type: 'raft',
                min_seats_available: search_params.total_pax,
                pax_count: pax_count,
                only_available: true
            });

            categorized_trips = {
                'Specific Tour': specific_tour,
                'Unavailable': specific_tour.length > 0 ? [] : tv_data_filter(data, { 
                    only_on_start_time: search_params.start_time,
                    tour_id: search_params.tour_id,
                    min_seats_available: search_params.total_pax,
                    pax_count: pax_count,
                    only_unavailable: true,
                })
            }
            if (vessel_type_order == 'raft') {
                categorized_trips['Others Rafts'] = rafts;
                categorized_trips['Others Morning Sails'] = morning_sails;
                categorized_trips['Others Afternoon Sails'] = afternoon_sails;
            }
            else {
                categorized_trips['Others Morning Sails'] = morning_sails;
                categorized_trips['Others Afternoon Sails'] = afternoon_sails;
                categorized_trips['Others Rafts'] = rafts;
            }

        }

        let total_cons_desc = 'Total for ' + search_params.adult;
        total_cons_desc += (search_params.adult <= 1) ? ' Adult' : ' Adults';
        let total_child = search_params.child + search_params.toddler;
        if (total_child > 0) {
            total_cons_desc += ' + ' + total_child;
            total_cons_desc += (total_child <= 1) ? ' Child' : ' Children';
        }
        if (opts.is_charter) {
            total_cons_desc = '';
        }

        let others_message_displayed = false;
        for (let tour_category in categorized_trips) {
            let trips = categorized_trips[tour_category];
            if (trips.length == 0) {
                continue;
            }
            let category_el = setup_category_el(results_el, tour_category, is_tour_specific);
            if (is_tour_specific && is_others_category(tour_category) && !others_message_displayed) {
                $('.bw-tours__category-header', category_el).before('<h2 class="bw-tours__category-header bw-tours__category-header--tour-specific">The specific tour you selected is not available on this date. Please select an alternate date or consider one of our other great adventures. <span style="font-style:italic">Mahalo!</span></h2>');
                others_message_displayed = true;
            }

            for (let i = 0; i < trips.length; i++) {
                let tv = trips[i];
                let tour = tours[tv.tour_id];
                let total_cost = (Number(search_params.adult) * Number(tv.adult_price_base)) + Number(total_child) * Number(tv.child_price_base);
                if (opts.is_charter) {
                    total_cost = (Number(tv.vessel[0].capacity_max) * Number(tv.adult_price_base));
                }
                const numWords = 25;
                const arr = strip_tags(tour.intro).split(/\s+/);
                const excerpt = arr.slice(0, numWords).join(' ');

                // Sold out, Not enough seats - Shows Sold out, add to waitlist.
                // Past cutoff time - Shows contact us
                // Always show available if a search wasn't triggered by the user bc just we have trip one per tour.
                let booking_status = get_booking_status(tv, opts.is_charter ? tv.vessel[0].capacity_max : total_count, search_params.toddler);

                let currency = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0, 
                    minimumFractionDigits: 0,
                });
                let card_el = $(set_placeholders(CARD_TEMPLATE, {
                    tour_image_url: encodeURI(tour.bw_v2_image_url),
                    tour_name: esc_html(tour.name),
                    highlights: tour_highlights(tour.highlights_bw, '&nbsp;<a href="#" class="bw-tours__card__details-open bw-mobile-only">More Info</a>'),
                    excerpt: excerpt,
                    tour_description: strip_tags(tour.intro + tour.description, '<button><div><span><p><a><strong><em>'),
                    faq_url: encodeURI(opts.faq_url),
                    tour_url: encodeURI(tour.tour_url ? tour.tour_url : '#'),
                    photos_url: '#',
                    total_cost: currency.format(total_cost),
                    total_cost_desc: esc_html(total_cons_desc),
                    tour_id: esc_html(tour.id),
                    trip_id: esc_html(tv.id),
                    seats_available: esc_html(tv.seats_available) + ' SEAT' + (tv.seats_available > 1 ? 'S' : ''),
                    capacity: esc_html(tv.vessel[0].capacity_max),
                    booking_status_class: 'bw-tours__card--' + esc_html(booking_status)
                }));
                $('.bw-tours__list', category_el).append(card_el);

                if (booking_status != BOOKING_STATUS_AVAILABLE) {
                    $('.bw-tours__button--book', card_el)
                        .attr('disabled', 'disabled')
                        .removeAttr('href');
                    let unavail_header = esc_html(tour.name) + ' is <span class="bw-red">unavailable</span> ';
                    let unavail_subheader = '';
                    let days = '';
                    if (booking_status == BOOKING_STATUS_AGE) {
                        unavail_header += 'due to age restrictions.';
                        unavail_subheader = '<a href="#" class="bw-change-passengers" data-ts-popup--container=".bw-tours__card[data-trip-id=' + esc_html(tv.id) + '] .bw-tours__unavailable" data-ts-popup-open="bwgp-widget">Change Passenger Selections</a>';
                    }
                    else {
                        if (booking_status == BOOKING_STATUS_SOLD_OUT) {
                            unavail_header = '<span class="bw-red">Sold Out:</span> ' + esc_html(tour.name);
                        }
                        else {
                            unavail_header += 'on this day.';
                        }
                        unavail_subheader = 'Available dates:';
                        let all_alt_days = tv_data_filter(data, { 
                            tour_id: tour.id,
                            one_trip_per_tour: false,
                            one_trip_per_date: true,
                            only_after_start_time: to_system_date(new Date()),
                            min_seats_available: search_params.total_pax,
                            pax_count: pax_count,
                            only_available: true,
                            charter_available: opts.is_charter
                        });
                        all_alt_days.sort(function(a, b) {
                            return (convertISOtoDate(a.start_time) > convertISOtoDate(b.start_time)) ? 1 : -1;
                        });
                        
                        let selected_dt_str = to_system_date(convertISOtoDate(search_params.start_time + ' 00:00:00'));
                        // find selected date idx
                        let selected_date_idx = all_alt_days.findIndex((day) => {
                            return selected_dt_str == to_system_date(convertISOtoDate(day.start_time));
                        });
                        // get +/- 3 available days
                        let alt_days = [];
                        if (selected_date_idx >= 3 && selected_date_idx <= all_alt_days.length - 3) {
                            let start_idx = selected_date_idx - 3;
                            for (let j = start_idx; j < start_idx + 3; j++) {
                                alt_days.push(all_alt_days[j]);
                            }
                            start_idx = selected_date_idx + 1;
                            for (let j = start_idx; j < start_idx + 3; j++) {
                                alt_days.push(all_alt_days[j]);
                            }
                        }
                        else {
                            alt_days = all_alt_days;
                        }
                        for (let j = 0; j < Math.min(alt_days.length, 6); j++) {
                            let alt_tv = alt_days[j];
                            const dt = convertISOtoDate(alt_tv.start_time);
                            let date_string = dt.toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                            });
                            const dow_string = dt.toLocaleDateString('en-US', {
                                weekday: 'short'
                            });
                            date_string += ' (' + dow_string + ')';
                            let data_date = alt_tv.start_time.split(' ')[0]; // just get date part.
                            days += '<a href="#" data-tour-id="' + esc_html(tour.id) + '" data-trip-id="' + esc_html(alt_tv.id) + '">' + date_string + '</a>';
                        }
                        if (alt_days.length == 0) {
                            // not avail in timeframe
                            unavail_subheader = 'No availabilities within 2 weeks of the selected date.';
                        }
                    }
                    const currDt = convertISOtoDate(tv.start_time);
                    card_el.append(set_placeholders(UNAVAILABLE_TEMPLATE, {
                        header: unavail_header,
                        subheader: unavail_subheader,
                        days: days,
                        current_day: currDt.toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                        })
                    }));
                    const note_el = $('.bw-tours__unavailable', card_el);
                    const y_spacing = note_el.position().top * 2;
                    const note_height = note_el.outerHeight() + y_spacing;
                    const content_height = $('.bw-tours__card__content', card_el).height();
                    if (content_height < note_height) {
                        $('.bw-tours__card__content', card_el).css('height', note_height + 'px');
                    }
                    if (opts.is_charter) {
                        $('.bw-tours__waitlist-link', note_el).hide();
                    }
                }
            }
        }

        if ($('> *', results_el).length == 0) {
            if (Number(opts.adult) + Number(opts.child) + Number(opts.toddler) > 49) {
                results_el.append(NO_RESULTS_TEMPLATE_CUSTOM);
            }
            else {
                results_el.append(NO_RESULTS_TEMPLATE);
            }
        }

        $('.bw-tours__card__details-open', results_el).off('click').on('click', function(e) {
            e.preventDefault();
            let card_el = $(this).closest('.bw-tours__card__content');
            let bottom_el = $('.bw-tours__card__section--bottom', card_el);
            let close_el = $('.bw-tours__card__details-close', card_el);
            if (bottom_el.is(':visible')) {
                bottom_el.hide();
                close_el.hide();
            }
            else {
                bottom_el.show();
                close_el.show().css('display', 'block');
            }
        });

        $('.bw-tours__card__details-close', results_el).off('click').on('click', function(e) {
            e.preventDefault();
            let card_el = $(this).closest('.bw-tours__card__content');
            $('.bw-tours__card__section--bottom', card_el).hide();
            $('.bw-tours__card__details-close', card_el).hide();
        });
        $('.bw-tours__button--book[href]', results_el).off('click').on('click', function(e) {
            e.preventDefault();
            let search_params = get_search_params();
            search_params.trip_id = $(this).closest('.bw-tours__card').attr('data-trip-id');
            search_params.tour_id = $(this).closest('.bw-tours__card').attr('data-tour-id');
            bw_events.on_submit(search_params, $(this));
        });

        $('.bw-tours__unavailable__days a[data-trip-id]', results_el).off('click').on('click', function(e) {
            e.preventDefault();
            let search_params = get_search_params();
            search_params.trip_id = $(this).attr('data-trip-id');
            search_params.tour_id = $(this).attr('data-tour-id');
            bw_events.on_submit(search_params, $(this));
        });

        $('.bw-tours__waitlist-link').off('click').on('click', function(e) {
            e.preventDefault();
            let trip_id = $(this).closest('.bw-tours__card').attr('data-trip-id');
            let search_params = get_search_params();
            search_params.trip_id = trip_id;
            
            let tv_data = tv_data_filter(data, { 
                id: trip_id,
            });
            cache('tour-vessel-detail-' + trip_id, tv_data);
            navigate(STEPS.waitlist.href(search_params));
        });

        // from within the description
        $('.bw-tours__card__section--bottom [data-toggle-class!=""][data-toggle-class]', results_el)
            .off('click')
            .on('click', function(e) {
            e.preventDefault();
            let card_content_el = $(this).closest('.bw-tours__card__section--bottom');
            let selector_to_show = '.' + $(this).attr('data-toggle-class');
            if ($(selector_to_show, card_content_el).hasClass('bw-d-none')) {
                $(this).attr('data-toggle-text', $(this).text()).text('Hide');
            }
            else {
                $(this).text($(this).attr('data-toggle-text'));
            }
            $(selector_to_show, card_content_el).toggleClass('bw-d-none');
        });

        io = observe_items({
            on_intersect: function(target_dom) {
                target_dom.src = target_dom.dataset.image;
            },
            selector: lazy_selector
        });
    }

    function setup_category_el(results_el, tour_category, is_tour_specific) {
        let cat_id = tour_category.toLowerCase().replace(' ', '-').replace(/[\W_]+/g,"-");
        let tour_category_name = !is_tour_specific ? esc_html(tour_category) : '';
        if (is_tour_specific && is_others_category(tour_category)) {
            tour_category_name = esc_html(tour_category).replace('Others ', '');
        }

        results_el.append(set_placeholders(CATEGORY_TEMPLATE, {
            tour_category_id: cat_id,
            tour_category_name: tour_category_name
        }));

        return $('.bw-tours__category-' + cat_id, results_el);
    }

    function get_booking_status(trip, seats_needed, toddler) {
        if (toddler && toddler > 0 && trip.vessel[0].toddlers_allowed != 1) {
            return BOOKING_STATUS_AGE;
        }
        else if (trip.max_seats_available <= 0) {
            return BOOKING_STATUS_SOLD_OUT;
        }
        else if (typeof seats_needed !== 'undefined' && trip.max_seats_available < seats_needed) {
            return BOOKING_STATUS_SOLD_OUT;
        }
        else if (Date.now() > (parseInt(trip.cutoff_timestamp * 1000) || Date.now())) {
            if (!is_allowing_late()) {
                return BOOKING_STATUS_AFTER_CUTOFF;
            }
        }

        return BOOKING_STATUS_AVAILABLE;
    }

    /**
     * Return true if the cookie to allow late or url param is set.
     */
    function is_allowing_late() {
        let allow_late = Cookies.get('ca-allow-late');
        return (typeof allow_late !== 'undefined' && allow_late == 1);
    }

    // filters results of the tv data
    function tv_data_filter(data, p) {
        let params = $.extend({
            id: false,
            tour_id: false,
            vessel_type: false,
            only_on_start_time: false, // hst
            min_seats_available: false,
            unavailable: false, // { adult: xx, child: yy, toddler: zz }
            ampm: false,
            one_trip_per_tour: true,
            one_trip_per_date: false
        }, p);

        let results = filter_data(data, params);

        if (params.id && results.length == 1) {
            return results[0];
        }

        if (params.one_trip_per_date) {
            let unique_trips_by_dates = {};
            for (let i = 0; i < results.length; i++) {
                let trip = results[i];
                if (trip.start_time in unique_trips_by_dates) {
                    continue;
                }
                unique_trips_by_dates[trip.start_time] = trip;
            }

            results = Object.values(unique_trips_by_dates);
        }

        if (!params.one_trip_per_tour) {
            return results;
        }

        let unique_tours = {};
        let tours = {};
        let tour_data = get_cache('tours').data;

        for (let i = 0; i < tour_data.length; i++) {
            tours[tour_data[i].id] = tour_data[i];
        }

        // now get trips with a unique tour-time combo.
        for (let i = 0; i < results.length; i++) {
            let tv_data = results[i];
            let tour_id = tv_data.tour_id;
            let key = tour_id + '-' + tv_data.start_time;;

            if (!(tour_id in tours)) {
                continue;
            }

            // find unique tours
            let seats_available = Number(tv_data.seats_available);
            if (!(key in unique_tours)) {
                let display_order = parseInt(tours[tour_id].display_order) || 0;
                tv_data.display_order = display_order;
                tv_data.max_seats_available = seats_available;
                unique_tours[key] = tv_data;
            }
            else {
                unique_tours[key].max_seats_available = Math.max(seats_available, unique_tours[key].max_seats_available);
                // get latest cutoff time
                unique_tours[key].cutoff_timestamp = Math.max(tv_data.cutoff_timestamp, unique_tours[key].cutoff_timestamp);

                // get lowest price
                let adult_price = Number(tv_data.adult_price_base);
                let child_price = Number(tv_data.child_price_base);
                let unique_tour_adult_price = Number(unique_tours[key].adult_price_base);
                let unique_tour_child_price = Number(unique_tours[key].child_price_base);

                if (adult_price < unique_tour_adult_price && child_price < unique_tour_child_price) {
                    unique_tours[key].adult_price_base = adult_price;
                    unique_tours[key].child_price_base = child_price;
                }
            }
        }

        // now sort and return
        return Object.values(unique_tours).sort(function(a, b) {
            return (a.display_order > b.display_order) ? 1 : -1;
        });
    }

    function is_booking_allowed(booking_status) {
        const book_ok = [BOOKING_STATUS_AVAILABLE];
        return book_ok.includes(booking_status);
    }
    
    /**
     * Return the first trip found for the tour_id.
     */
    function get_sample_trip(data, tour_id) {
        let results = tv_data_filter(data, { 
            tour_id: tour_id,
            one_trip_per_tour: true
        });
    
        return (results && results.length > 0) ? results[0] : null;
    }
}

// convert search params to url params
export const to_url_params = (search_params) => {
    let has_required_keys = [
        'start_time',
        'adult',
        'child',
        'toddler'
    ].every((i) => search_params.hasOwnProperty(i));

    if (!has_required_keys) {
        return false;
    }

    let url_params = {
        step: STEPS.tours.name,
        start_time: search_params.start_time,
        adult: search_params.adult,
        child: search_params.child,
        toddler: search_params.toddler,
    }
    if (search_params.tour_id && search_params.tour_id !== 0) {
        url_params.tour_id = search_params.tour_id;
    }
    if (search_params.vessel_type) {
        url_params.vessel_type = search_params.vessel_type;
    }
    if (search_params.is_charter) {
        url_params.is_charter = search_params.is_charter;
    }

    return url_params;
}

export const has_required_params = (params) => {
    return !!(params.start_time && params.start_time != 0
           && params.adult && params.adult != 0 
    );
}

function is_others_category(tour_category) {
    return tour_category.indexOf('Others') === 0;
}