export const type = (num) => {
    let t = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/,
        amex: /^3[47][0-9]{13}$/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    };
    for (let n in t) {
        if (t[n].test(num)) {
            return n
        }
    }
}

// building JSON resquest and submitting request to Auth.Net server
export const get_token = (payment_config, el_context, on_done) => {
    let name = $('#cardholder_name', el_context).val();
    let cc = $('#cc_number', el_context).val();
    let cvv = $('#cvv_code', el_context).val();
    let exp_month = $('#exp_month', el_context).val();
    let exp_year = $('#exp_year', el_context).val();

    var auth_data = {};
    auth_data.apiLoginID = payment_config.api_login;
    auth_data.clientKey = payment_config.client_key;

    var card_data = {};
    card_data.cardNumber = cc;
    card_data.month = exp_month;
    card_data.year = exp_year;
    card_data.cardCode = cvv;
    card_data.fullName = name;

    var secure_data = {};
    secure_data.authData = auth_data;
    secure_data.cardData = card_data;

    //Call Accept method
    Accept.dispatchData(secure_data, function(response) {
        // see https://community.developer.authorize.net/t5/Integration-and-Testing/Accept-js-E00003-Root-element-is-missing/td-p/63213
        // for weirdness that there's a prefetch request (OPTIONS) that always fails, so we want to ignore that
        // this is NOT the error response from request.api, which is more specific - E00003 Root element not found
        // we also can't hijack the outbound request to just ignore anything that was an OPTIONS, which is lame
        // so this probably loses a bunch by swallowing errors it shouldn't.  Oh well.
        if (response.messages.resultCode == 'Error' && 
                (response.messages.message[0].code == 'E_WC_14' || response.messages.message[0].code == 'E00001')
        ) {
            ; // do nothing
        }
        else {
            on_done(response);
        }
    });
};


// evaluate a response
export const eval_response = (response) => {
    if (response.messages.resultCode === 'Error') {
        let errors = [];
        for(let message of response.messages.message) {
            let error_fields = [];
            switch(message.code) {
                case 'E_WC_05':
                    error_fields.push('cc_number');
                    break;
                case 'E_WC_06':
                    error_fields.push('exp_month');
                    break;
                case 'E_WC_07':
                    error_fields.push('exp_year');
                    break;
                case 'E_WC_08':
                    error_fields.push('exp_month');
                    error_fields.push('exp_year');
                    break;
                case 'E_WC_15':
                    error_fields.push('cvv_code');
                    break;
                case 'E_WC_16':
                    error_fields.push('cc_zip');
                    break;
                case 'E_WC_17':
                    error_fields.push('cardholder_name');
                    break;
                default:
                    error_fields.push('');
                    break;
            }

            for(let error_field of error_fields) {
                let error = {};
                error.key = error_field;
                error.text = message.text;
                errors.push(error);
            }
        }

        return {
            success: false,
            errors: errors
        };
    }
    else {
        return {
            success: true,
            opaque_data: response.opaqueData,
        };
    }
}

export const set_data_to_process_card = (options) => {
    let opaque_data = options.opaque_data || { };

    $('#dataDescriptor').val(opaque_data.dataDescriptor);
    $('#dataValue').val(opaque_data.dataValue);

    // could clear form values here if we should hide them
}

// capture input and assume that it gets 300ms to write and that everything starts with a %
export const handle_reader_input = (options) => {
    const name_el = $('#cardholder_name');

    let card_input_timer = null;
    let is_card_input = false;
    let card_input = '';

    name_el.off('keypress').on('keypress', function(e) {
        let input_char = String.fromCharCode((typeof e.which == "number") ? e.which : e.keyCode);
        
        if (is_card_input == true) {
            e.preventDefault();

            card_input = card_input + input_char;
      
            clearTimeout(card_input_timer);

            card_input_timer = setTimeout(function() {
                is_card_input = false;

                parse_reader_input(card_input);
            }, 300);
        }
        else {
            if (input_char == '%') {
                e.preventDefault();

                is_card_input = true;
            }
        }
    });
}

// card data is in the format of
// %B{15-16 digit card number}}^{last/first name}                 ^{YYMM}2011904649530000000000000000?;{card number}={YYMM}20119041981700000?
function parse_reader_input(card_input) {
    const name_el = $('#cardholder_name');
    const cc_el = $('#cc_number');
    const month_el = $('#exp_month');
    const year_el = $('#exp_year');
    const cvv_el = $('#cvv_code');

    let card_data = card_input.split('^');

    let name = card_data[1].split('/');
    name_el.val((name[1] + ' ' + name[0]).trim());

    cc_el.val(card_data[0].replace(/[^0-9]*/, ""));

    month_el.val(card_data[2].substring(2, 4));
    year_el.val(card_data[2].substring(0, 2));

    cvv_el.focus();				
}

