import { 
    CLOSE_BTN_TEMPLATE, 
    SELECTED_TOUR_TEMPLATE, 
    CONTACT_INFO_FIELDS, 
    ERROR_MESSAGE
} from 'booking-widget/v2/templates/shared';

export const TOUR_PICKER_TEMPLATE = `
<div class="bw-popup bwtp-widget" id="bwtp-widget" aria-hidden="true" data-popup-name="bwtp-widget">
    <div class="bw-popup__container bwtp-widget__container" role="dialog" aria-popup="true" aria-labelledby="bwtp-widget-title">
        <main class="bw-popup__content">
            <h3>Select Tours</h3>
            <form name="" class="">
                <div class="bw-multiselect">
                    [tours_list]
                </div>
            </form>
            <a href="#" class="bw-button bw-mt--30" data-ts-popup-close>OK</a>
        </main>
    </div>
</div>`;

export const TOUR_PICKER_OPTION = `
<label for="bwtp-tour-[tour_id]" class="">
    <input type="checkbox" class="bw-mr-5" name="tours" value="[tour_id]" id="bwtp-tour-[tour_id]">
    [tour_name]
</label>
`;


export const WAITLIST_TEMPLATE = `
<div class="bw bw-waitlist">
    <div class="bw-header--trigger"></div>
    <div class="bw-header">
        ${CLOSE_BTN_TEMPLATE.replace('Close Booking', 'Close')}
    </div>
    <div class="bw-title">
        <h1 class="bw-text-center bw-mt--12 bw-mb--12">Waitlist Signup</h1>
        <p>Join the waitlist and get in line for your next adventure!  Fill out your contact info so we can reach you when a tour of your preference opens up.</p>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <div class="bw-form-wrap">

            <h2>Are your dates flexible?</h2>
            <div class="bw-big-radios" data-key="flexible-dates">
                <a href="#" class="bw-big-radios__radio" data-value="yes">Yes<span class="flexible-date-string" style="display:none"></span></a>
                <a href="#" class="bw-big-radios__radio" data-value="no">No</a>
            </div>

            <h2>Join other tour waitlists?</h2>
            <div class="bw-big-radios bw-big-radios--tours" data-key="flexible-tours" >
                <a href="#" class="bw-big-radios__radio" data-value="yes" data-ts-popup-open="bwtp-widget" data-ts-popup--container=".bw-big-radios--tours">Yes<span class="flexible-tour-string"  style="display:none"></span></a>
                <a href="#" class="bw-big-radios__radio" data-value="no">No</a>
            </div>

            ${ERROR_MESSAGE}

            <h2>Waitlist Contact Information</h2>

            <form class="bw-form" action="">
                <div class="bw-row">
                    <input type="text" name="contact_first_name" placeholder="First Name*" data-target="contact-first-name" required>
                    <input type="text" name="contact_last_name" placeholder="Last Name*" data-target="contact-last-name" required>
                </div>
                ${CONTACT_INFO_FIELDS}

                <input type="submit" class="bw-button bw-form-submit" value="Get on Waitlist">

                <input type="hidden" name="flexible_dates" class="bw-hidden-flexible" value="">
                <input type="hidden" name="flexible_tours" class="bw-hidden-flexible" value="">
            </form>
        </div>
    </div>
</div>
`;


export const WAITLIST_SUCCESS_MESSAGE = `
<p>We will contact you if space becomes available.  You've been added to our waitlist for <strong>[passenger_counts]</strong> on all of the following dates and tours:</p>
<div class="bw-row bw-flex-direction-column bw-flex-md-direction-row">
    <div class="bw-flex-md-basis-50">[dates]</div>
    <div class="bw-flex-md-basis-50">[tours]</div>
</div>`;