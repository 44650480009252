import { get as get_data } from 'data/data';
import log from 'utils/log';

export const contact_passengers = (options) => {
    if (options == null) {
        options = {};
    }

    let options_on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});

    delete options.on_done;

    log('sending message to passenger ' + options.passenger_id);

    let defaults = {
        endpoint: 'notification/contact_passengers',
        limit: '',
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }

    return get_data($.extend(true, {}, defaults, options));
}

// pass in options.data.emails as a comma delimited string
// pass in options.reservation_id 
export const send_confirmation_email = (options) => {
    if (options == null) {
        options = {};
    }

    let options_on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.version = options.version ? options.version : BOOKING_VERSION;

    delete options.on_done;

    log('sending message to passenger ' + options.passenger_id);

    let defaults = {
        endpoint: 'notification/send_reservation_complete',
        limit: '',
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }

    return get_data($.extend(true, {}, defaults, options));
}