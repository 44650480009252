export function unparams(str) {
    return str.split('&').reduce(function (params, param) {
        var paramSplit = param.split('=').map(function (value) {
            return decodeURIComponent(value.replace(/\+/g, ' '));
        });
        var key = paramSplit[0];
        if (key.indexOf('[]') !== -1) {
            key = key.replace('[]', '');
            if (key in params) {
                params[key].push(paramSplit[1]);
            }
            else {
                params[key] = [paramSplit[1]];
            }
        }
        else {
            params[paramSplit[0]] = paramSplit[1];
        }
        return params;
    }, {});
}


// takes a filter and gets its parts
// filter is query format of like tour.id.in(146,147),tour_vessel.start_time.gt(2019-03-22),tour_vessel.start_time.lt(2019-03-31)
export function get_filters(name, operator, filter_string) {
    if (filter_string.indexOf(name) == -1) {
        return false; // return falsefilter name not found
    }

    // split on the name in case we have multiple filters, starting at the first instance
    let names = filter_string.substr(filter_string.indexOf(name));
    let has_operator = !(operator == undefined || operator == null || operator == 'undefined' || operator == '');
    
    names = names.split(name);
    let filters = [];

    for (let i = 0; i < names.length; i++) {
        // trim whitespace and trailing comma
        let filter = names[i].trim().replace(/,\s*$/, "");

        if (filter == '') {
            continue;
        }

        filter = filter.substr(0, filter.indexOf(')')); // remove trailing ')'
    
        let parts = filter.split('(');
        let values = parts[1].replace(')', '').split(',');

        for (let value of values) {
            if ((!has_operator) || operator == parts[0].replace('.', '')) {
                filters.push({
                    operator: parts[0].replace('.',''),
                    value: decodeURI(value),
                });
            }
        }
    
    }

    return filters;
}

export function set_input_from_uri_hash(container_el) {
    container_el = container_el || $('body');

    $('input', container_el).each(function() {
        let el = $(this);

        // skip any autocompletes
        if (el.attr('data-autocomplete-key') != undefined && el.attr('data-autocomplete-identifier') != 'true') {
            return;
        }
        let name = el.attr('name');
        let type = el.attr('type');
        let val = el.val();
        let hash = window.location.hash.replace(/^#!/, '');
        let operator = el.attr('data-filter-operator') || null;
        let filters = get_filters(name, operator, hash);

        // reset this input element if there are no filters for this name
        if (filters == false) {
            if (type != 'checkbox') {
                el.val('');
            }

            if (el.hasClass('form-datepicker')) {
                el.datepicker('setDate', '');
            }
            
            return;
        }

        for (let filter of filters) {
            // if its a checkbox and the value matches, check it
            if (type == 'checkbox' && val == filter.value) {
                el.prop('checked', true);

                // this was the shit right here
                // motherfucking checkboxes don't trigger a .change() event
                // when you set the motherfucking property
                el.trigger('change'); // this is stupid

                break;
            }

            // if its a datepicker, set the date
            // there is some oddness that Date() thinks its converting FROM UTC
            // so we have to + a day after the parsing for start dates
            if (el.hasClass('form-datepicker')) {
                let date = new Date(filter.value);

                if (!operator || operator == 'gt') {
                    date.setDate(date.getDate() + 1);
                }

                el.datepicker('setDate', date);
                break;
            }

            if (el.hasClass('textmultivalue')) { // for textboxes that use 'in' operator that can take multiple values separated by ',', e.g. WHERE IN (1234,2321,99335)
                el.value += (filter.value + ',');
            }
            

            // otherwise, maybe an autocomplete
            // special logic for each
            if (name == 'reservation.group_id') {
                reservation_group.get({
                    id: filter.value,
                    target_el: el,
                    on_done: function(data) {
                        $('[data-autocomplete-key="reservation_group"][data-autocomplete-field="name"]').val(data.data.name);
                    },
                })

                // let it fall through to set the id, too
            }

            // otherwise, set the val
            if (type != 'checkbox') {
                el.val(filter.value)
                break;
            }
        }
    });
}