import { get as get_data } from 'data/data';
import { get as get_cache, cache } from 'utils/cache';

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'booking_setting',
        fields: 'key,value',
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const get_food_preferences = (options) => {
    let defaults = {
        endpoint: 'booking_setting/food_preferences',
        field: '',
    }

    return get($.extend(true, {}, defaults, options));
}

export const get_notice = (options) => {
    let defaults = {
        endpoint: 'booking_setting/notice',
        version: BOOKING_VERSION,
        field: '',
    }

    return get($.extend(true, {}, defaults, options));
}

// get all ca settings like food_preferences and age_ranges
export const get_all = (options) => {
    if (options == null) {
        options = {};
    }
    
    let cache_key = 'booking_settings';
    if (!$.isEmptyObject(options)) {
        cache_key += '_' + JSON.stringify(options)
    }

    let defaults = {
        endpoint: 'booking_setting/all',
        version: BOOKING_VERSION,
        field: '',
        on_done: function(json) {
            if (json.data) {
                cache(cache_key, json);

                // cache each setting separately too
                for (let key in json.data) {
                    if (json.data[key]) {
                        cache('booking_settings-' + key, json.data[key]);
                    }
                }
            }
        }
    }
    if (options.on_done) {
        let on_done = options.on_done;
        options.on_done = function(json) {
            defaults.on_done(json);
            on_done(json);
        }
    }
    let cached = get_cache(cache_key);
    if (cached !== false) {
        cached.cached = true;
        if (options && options.on_done) {
            options.on_done(cached);
        }
        return null;
    }
    return get($.extend(true, {}, defaults, options));
}