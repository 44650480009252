import { get_settings as get_agency_settings } from 'data/agency';
import { get as _get_reservation } from 'data/reservation';
import { get_gift_certificate as _get_gift_certificate } from 'data/reservation';
import { send_confirmation_email } from 'data/notification';

import { STEPS, show_fullscreen_modal, navigateToClose } from 'booking-widget/v2/bw';
import { get_tours, get_trip, set_transaction } from 'booking-widget/v2/common/data';
import { 
    set_placeholders,
    setup_selected_tour_day,
    show_loading,
    hide_loading,
    format_currency
} from 'booking-widget/v2/common/util';

import { CONFIRMED_TOUR_TEMPLATE, CONFIRMED_GC_TEMPLATE } from 'booking-widget/v2/templates/confirmed';
import { MODAL_TEMPLATE, PAGE_ERROR } from 'booking-widget/v2/templates/shared';

import { setup as setup_nav, setup_close_btn } from 'booking-widget/v2/widgets/nav';

export const load = (options, bw_events) => {
    if (!options) {
        options = {};
    }

    let opts = $.extend(true, {
        context_el: $('body'),
        on_init: function() {},
    }, options);
    if (!opts.reservation_id) {
        display_page_error('No Reservation Found', 'Please make sure you have the correct URL.')
        return;
    }

    const widget_el = show_fullscreen_modal(opts.context_el);
    const tours_params = {
        id: opts.tour_id, 
        cache_name_prefix: 'tour-', 
        fields_append: 'has_meal'
    }
    const is_charter = opts.is_charter ? opts.is_charter : undefined;
    show_loading(widget_el, true);
    $.when(get_reservation(opts.reservation_id), get_trip(opts.trip_id), get_tours(tours_params), get_agency_settings())
        .then(function(res_json, tv_data, tours_json, booking_setttings_json) {
            if (res_json.success == false || (!tv_data && res_json.data.reservation_type !== 'Gift')) {
                display_page_error('Error', 'Reservation not found or has past.');
                hide_loading(widget_el, true);
                return;
            }
            let tour = tours_json ? tours_json.data : {};
            if (res_json.data.reservation_type === 'Gift' && !res_json.data.trip_id) {
                widget_el.addClass('bw-gc');
                render_page_gc(res_json.data, opts);
            }
            else {
                render_page(res_json.data, tv_data, tour, opts);
            }

            opts.on_init(opts);

            hide_loading(widget_el, true);
            bw_events.on_loaded(opts);
        }, function() {
            hide_loading(widget_el, true);
        })
    ;

    function display_page_error(title, message) {
        widget_el.append(set_placeholders(PAGE_ERROR, {
            error_title: title,
            error_message: message
        }));
    }

    function get_reservation(id) {
        return _get_reservation({
            id: id,
            fields: 'number,reservation_type,is_paid,trip_id',
        });
    }

    function render_page_gc(data, tx_info) {
        let payment_info_html = '';

        if (tx_info.payment_amount && tx_info.cc_last4) {
            payment_info_html += ' to be charged to card ending in ' + tx_info.cc_last4;
        }

        let expires = new Date();
        expires.setFullYear(expires.getFullYear() + 5);

        let expires_str = expires.toLocaleDateString('en-US', { 
            day: 'numeric', 
            month: 'numeric', 
            year:'numeric'
        });
        
        let confirmed_el = $(set_placeholders(CONFIRMED_GC_TEMPLATE, {
            email: tx_info.email,
            gift_code: data.number,
            expires: expires_str,
            gc_value: format_currency(tx_info.payment_amount),
            selected_tour_params: {
                message: '<strong>Gift Code: ' + data.number + '</strong>',
                payment_info_html: payment_info_html,
                prices: opts.prices,
            }
        }));
        widget_el.append(confirmed_el);
        setup_close_btn(widget_el, STEPS.confirmed.name, function() {
            set_transaction(null);
        });
        setup_email_form(tx_info);
    }

    function render_page(res_data, tv_data, tour, tx_info) {
        let payment_info_html = '';
        let passenger_names = [];
        let primary_name = '';
        if (tx_info) {
             if (tx_info.payment_amount && tx_info.cc_last4) {
                payment_info_html += ' to be charged to card ending in ' + tx_info.cc_last4;
            }

            for (let i = 0; i < tx_info.first_name.length; i++) {
                let food_pref_str = '';
                if ((i < opts.food_preference.length && opts.food_preference[i] != '') ) {
                    food_pref_str = ' - <em>' + opts.food_preference[i] + '</em>';
                }
                let is_child_str = ' (adult)';
                if ((i < opts.is_child.length) && opts.is_child[i] == 'on') {
                    is_child_str = ' (child)';
                }
                let name = '';
                if (tx_info.first_name && i < tx_info.first_name.length) {
                    name = (opts.first_name[i] != null) ? opts.first_name[i] : '';
                }
                if (tx_info.last_name && i < tx_info.last_name.length) {
                    if (tx_info.last_name[i] != null && tx_info.last_name[i].length > 0) {
                        name += ' ' + tx_info.last_name[i];
                    }
                }
                if (i == 0) {
                    primary_name = name;
                }
                if (name == '') {
                    name = 'Passenger ' + (i+1);
                }
                passenger_names.push(name + is_child_str + food_pref_str);
            }
        }

        if (payment_info_html == '') {
            // include some kind of payment info
            if (res_data.is_paid == 1) {
                payment_info_html += '<span class="green">Paid in full</span>';
            }
            else {
                payment_info_html += '<span class="bw-red">Requires additional payment.  Please contact the reservation desk.</span>';
            }
        }

        const restrictions = restrictions_string(tv_data.vessel[0].toddlers_allowed, tv_data.vessel[0].pregnant_allowed).toLowerCase();
        let confirmed_el = $(set_placeholders(CONFIRMED_TOUR_TEMPLATE, {
            tour_video_url: tour.embed_video_url,
            // res_complete_partial has [[restrictions]] - change to single []
            tour_res_complete_partial: tour.res_complete_partial.replace(/\[\[restrictions\]\]/g, '[restrictions]'),
            // restrictions tag is in res_complete_partial, so this needs to be after that.
            restrictions: '<p>We are ' + restrictions + '</p>', 
            email: tx_info.email,
            url_root: URL_ROOT,
            url_root_public: URL_ROOT_PUBLIC,
            res_number: tx_info.reservation_number,
            link_directions: encodeURI(tv_data['location'][0]['directions_uri']),
            selected_tour_params: {
                message: '<strong>Reservation #' + tx_info.reservation_number + '</strong>',
                tv_data: tv_data,
                tour: tour,
                prices: tx_info.prices,
                adult_count: tx_info.adult,
                child_count: tx_info.child,
                passenger_names: passenger_names,
                primary_name: primary_name,
                primary_email: opts.email,
                primary_phone: opts.phone,
                payment_info_html: payment_info_html,
                guest_count: passenger_names.length,
                notes: opts.reservation_note,
                is_charter: opts.is_charter
            }
        }));
        widget_el.append(confirmed_el);
        if (tx_info.child > 0 && !opts.is_charter) {
            $('.bw-selected-tour__price-list__line--child', widget_el).show();
        }
        if (Number(tx_info.prices.gift_code_amount) > 0) {
            $('.bw-selected-tour__price-list__line--gift', widget_el).show();
        }
        if (Number(tx_info.prices.discount) > 0) {
            $('.bw-selected-tour__price-list__line--discount', widget_el).show();
        }
        if (!opts.is_charter) {
            $('.bw-selected-tour__passengers', widget_el).show();
        }
        $('.bw-selected-tour__notes', widget_el).show();
        setup_selected_tour_day(confirmed_el, tv_data.start_time, false);
        setup_nav(widget_el, STEPS.confirmed.name);
        setup_close_btn(widget_el, STEPS.confirmed.name);
        setup_email_form(tx_info);
    }

    function setup_email_form(tx_info) {
        $('form.bw-form--email', widget_el).off('submit').on('submit', function(e) {
            e.preventDefault();
            let form_el = $(this);
            let input_el = $('input[name="emails"]', form_el);
            let textarea_el = $('textarea[name="message"]', form_el);
            show_loading(form_el);
            send_confirmation_email({
                data: {
                    reservation_id: tx_info.reservation_id,
                    emails: input_el.val(),
                    message: textarea_el.val()
                },
                on_done: function(json) {
                    hide_loading(form_el);
                    input_el.val('');
                    textarea_el.val('');
                    if (json.success) {
                        $('.bw-form--email-message', form_el)
                            .removeClass('bw-red')
                            .addClass('bw-green')
                            .text('Emails sent successfully')
                        ;
                    }
                    else {
                        $('.bw-form--email-message', form_el)
                            .removeClass('bw-green')
                            .addClass('bw-red')
                            .text('Emails failed to send, please check your email addresses and try again.')
                        ;
                    }
                }
            });
            return false;
        });

    }
}

function restrictions_string(toddlers_allowed, pregnant_allowed) {
    let str = 'Unable to accommodate ';
    if (pregnant_allowed == 2) {
        str += ' <strong>anyone over 26 weeks of pregnancy</strong> and';
    }
    else if (pregnant_allowed == 0) {
        str += ' <strong>anyone pregnant</strong> and';
    }
   
    if (toddlers_allowed == 0) {
        str += ' <strong>children under 6</strong>';
    }
    else {
        str += ' <strong>children under 3</strong>';
    }
    return str + '.';
}