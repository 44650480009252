/** @module data/agency */

import { get as get_data } from 'data/data';
import { get as get_cache, cache } from 'utils/cache';

/**
 * Private cache of Agencies
 * @private
 * @type {?object[]}
 */
let agencies_list = null;

/**
 * Private cache of Agencies and desks
 * @private
 * @type {?object[]}
 */
let agencies_desks_list = null;

/**
 * Populate dropdown with list of agencies
 *
 * @public
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
export function populate_agency_list(options) {
    if (options == null) {
        options = {};
    }
    options.context_el = options.context_el || $('body');
    options.filters = options.filters || '';
    options.on_change = options.on_change || null;

    if (agencies_list === null) {
        agencies_list = {};
    }
    else if (agencies_list[options.filters] !== null) {
        load(options);
        return;
    }

    let filters = '&filters=is_active.eq(1)';
    if (options.filters != '') {
        filters += '&' + options.filters;
    }

    $.getJSON(
        `${__url_root}api/agency?fields=id,name,availability_override_description` + 
        filters + 
        '&limit=200',
        function (json) {
            // cache the whole list
            agencies_list[options.filters] = json.data;

            load(options);
        }
    );
}

/**
 * Populate dropdown with list of agencies
 *
 * @public
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
export function populate_agency_desk_list(options) {
    options.context_el = options.context_el || $('body');
    options.agency_id = options.agency_id || null;
    options.default_agency_id = options.default_agency_id  || null;
    options.force = options.force || false;

    if (agencies_desks_list !== null && options.force != true) {
        load_with_desks(options);
        return;
    }

    let uri = __url_root + 'api/agency?' +
        'fields=id,name,phone,commission_rate,payment_type,is_payment_type_balance_due,is_payment_type_voucher,is_internal' +
        '&include=agency_desk' +
        '&expand=agency_desk' +
        '&order=agency.name' +
        '&limit=500'
    ;

    if (options.agency_id != null) {
        uri += '&filters=agency.id.eq(' + options.agency_id + ')';
    }
    else {
        // includes does not accept filters, filter out inactive desks later.
        uri += '&filters=agency.is_active.eq(1)';
        if (options.selected_agency_id != null) {
            uri += ',agency.id.oeq(' + options.selected_agency_id + ')';
        }
    }

    $.getJSON(
        uri,
        function (json) {
            // cache the whole list
            agencies_desks_list = json.data;

            load_with_desks(options);
        }
    );
}

/**
 * Populate target dropdown w/ cached agencies
 *
 * @private
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
function load(options) {
    let select_el = $('select[data-target=list-agency]', options.context_el),
        default_val = select_el.data('default');

    if (!select_el.length) {
        return;
    }

    select_el
        .empty()
        .append('<option value="">Agency</option>');

    if (!agencies_list[options.filters]) {
        return;
    }

    for (let row of agencies_list[options.filters]) {
        select_el.append(`<option value="${row.id}">${row.name}</option>`);
    }

    if (default_val) {
        select_el.val(default_val);
    }

    if (options.on_change) {
        select_el.on('change', function() {
            let agency_id = $(this).val();
            let agency_data = agencies_list[options.filters];
            let agency = agency_data.find( function(obj) {
                return obj.id === agency_id;
            })
            options.on_change(select_el, agency);
        });
    }


}

/**
 * Populate target dropdown w/ cached agencies
 *
 * @private
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
function load_with_desks(options) {
    let select_el = $('select[data-target=agency-desk-list]', options.context_el);
    options.selected_agency_id = options.selected_agency_id || null;
    options.selected_agency_desk_id = options.selected_agency_desk_id || '""';
    
    if (!select_el.length) {
        return;
    }

    select_el
        .empty()
        .append('<option value="">Agency / Desk</option>');

    if (!agencies_desks_list) {
        return;
    }

    for (let row of agencies_desks_list) {
        if (row.agency_desk) {
            // sort the agency desks because API doesn't support ordering of includes
            row.agency_desk.sort(function(a, b){
                if ( a.name < b.name )
                    return -1;
                return 1;
            });

            for (let desk of row.agency_desk) {
                if (desk.is_active != 1 && desk.id != options.selected_agency_desk_id) {
                    continue;
                }
                let label = row.name + ' - '  + desk.name;
                
                if (desk.phone) {
                    label += ' (' + desk.phone + ')';
                }
                select_el.append(`
                    <option
                        value="${row.id}"
                        data-agency-desk-id="${desk.id}"
                        data-is-internal="${row.is_internal}"
                        data-is-payment-type-balance-due="${row.is_payment_type_balance_due}"
                        data-is-payment-type-voucher="${row.is_payment_type_voucher}"
                        data-commission-rate="${row.commission_rate}">${label}
                    </option>`);
            }
        }
    }

    select_el
        .on('change', function() {
            let selected_el = $('option:selected', $(this));
            $('input[name=agency_desk_id]', select_el.parent()).val(selected_el.attr('data-agency-desk-id'));
        })
    ;

    if (options.selected_agency_id != null) {
        $('option[value=' + options.selected_agency_id + '][data-agency-desk-id=' + options.selected_agency_desk_id + ']', select_el)
            .attr('selected', true)
            .trigger('change')
        ;
    }

}

// evaluates commission rate
export const get_commission_rate = (el) => {
    let comm = el.data('commission-rate');
    return evaluate_commission_rate(comm);
}

export const evaluate_commission_rate = (comm) => {
    if (comm == null || comm == '' || isNaN(comm)) {
        return 0;
    }

    return Number(comm);
}

// evaluates payment type
export const get_payment_type = (el) => {
    if (el.data('is-payment-type-balance-due')) {
        return 'Balance Due';
    };
    if (el.data('is-payment-type-voucher')) {
        return 'Voucher';
    };
    return 'Referral';
}

export const get_settings = (options) => {
    let d = $.Deferred();
    if (options == null) {
        options = {};
    }

    options.on_done = options.on_done ? options.on_done : function() {};
    options.version = options.version ? options.version : BOOKING_VERSION;
    
    let cache_name = (options && options.cache_name) ? options.cache_name : 'booking-settings';
    let cached = get_cache(cache_name);
    if (cached != false) {
        options.on_done(cached);
        d.resolve(cached);
        return d.promise();
    }
    let endpoint = 'agency/booking_settings';
    get_data({
        endpoint: endpoint,
        version: options.version,
        on_done: function(json) {
            if (cache_name != '') {
                cache(cache_name, json);
            }
            options.on_done(json);
            d.resolve(json);
        }
    });

    return d.promise();
}