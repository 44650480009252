import { post as post_data } from 'data/data';

export const post = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'cart',
    }

    return post_data($.extend(true, {}, defaults, options));
}