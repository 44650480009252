import { get_future_prices } from 'data/tour-price';
import { get as get_cache, cache } from 'utils/cache';

export const setup = (options, bw_events) => {
    if (!options) {
        options = {};
    }
    let opts = $.extend(true, {
        context_el: $('body'),
        set_gc_parameters: function() {},
    }, options);


    $.when(get_future_prices()).then(function(prices_json){
        cache('prices', prices_json);

        /**
         * handles params with either:
         *  { tour_id, adult_count, child_count }
         * or
         *  {amount, taxes }
         */
        $(document).on('bw-gc-submit', function(e, params) {
            e.preventDefault();
            let data = {};
            if (params.tour_id) {
                // get tour amounts
                let prices = get_tour_amounts(params.tour_id);
                if (prices.length == 0) {
                    bw_events.on_error('No tour prices found.');
                    return;
                }
    
                let totals = {
                    base: 0,
                    tax: 0,
                    fees: 0
                };
                for (let i = 0; i < prices.length; i++ ) {
                    let price = prices[i];
                    let pax_count = 0;
                    if (price.is_child != 1) {
                        pax_count = (params.adult_count && params.adult_count > 0) ? params.adult_count : 0;
                    }
                    else {
                        pax_count = (params.child_count && params.child_count > 0) ? params.child_count : 0;
                    }

                    // calculate the same as cc
                    for(let j = 0; j < pax_count; j++) {
                        totals.base += Number(price.base);
                        totals.tax += Number((Number(price.tax) * Number(price.base)).toFixed(2));
                        totals.fees += Number((Number(price.fees) * Number(price.base)).toFixed(2));
                    }
                }
    
                data.amount_only = Number(totals.base).toFixed(2);
                data.amount_only_taxes = Number(totals.tax).toFixed(2);
                data.amount_only_fees = Number(totals.fees).toFixed(2);
            }
            else {
                data.amount_only = Number(params.amount).toFixed(2);
                data.amount_only_taxes = null;
                data.amount_only_fees = null;
                if (params.taxes) {
                    data.amount_only_taxes = Number(params.taxes).toFixed(2);
                }
                if (params.fees) {
                    data.amount_only_fees = Number(params.fees).toFixed(2);
                }
            }
            data.trip_id = null;
            bw_events.on_submit(data);
        });

        bw_events.on_loaded(prices_json.data);
    });

};

function get_tour_amounts(tour_id) {
    let prices_json = get_cache('prices');

    if (prices_json.success == true && prices_json.data) {
        let prices = prices_json.data.filter(function(price_data) {
            return (price_data.tour_id == tour_id);
        });

        // get the first set of prices.
        let first_set = [];
        for (let i = 0; i < prices.length; i++ ) {
            if (i == 0 || first_set[0].is_child != prices[i].is_child) {
                first_set.push(prices[i]);
            }
            if (first_set.length == 2) {
                break;
            }
        }

        return first_set;
    }
    return [];
}