/** @module utils/cache */

import log from 'utils/log';

/**
 * Massive cache for all things
 *
 * @private
 * @type {object}
 */
let cache_data = {};

/**
 * Get cached value
 *
 * @public
 * @param {!string} key - Key of cached item to fetch
 * @returns {(boolean|*)} - False if key does not exist, otherwise returns the cached value
 */
export function get(key) {
    if (!cache_data.hasOwnProperty(key)) {
        const value = storage_get(key);
        if (value !== false) {
            return value;
        }

        return false;
    }

    // same as for the writing - if we return the actual object, then somebody will likely edit it
    let copy = $.extend({}, cache_data[key]);

    return copy;
}

/**
 * Removes item from cache, if it exists
 *
 * @public
 * @param {!string} key - Key of cache item to remove
 * @returns {void}
 */
export function remove(key) {
    if (!cache_data.hasOwnProperty(key)) {
        return;
    }

    delete cache_data[key];
}

/**
 * Remove caches with keys starting with prefix
 */
export function remove_key_with_prefix(prefix) {
    for (const key in cache_data) {
        if (cache_data.hasOwnProperty(key) && key.startsWith(prefix)) {
            delete cache_data[key];
        }
    }
}

/**
 * Add item to cache. Overrides any existing value w/ same the key.
 * If session_expire_dt is a date object, save to window.sessionStorage as a backup
 * 
 * @public
 * @param {string} key - Key
 * @param {*} value - Value to cache
 * @param {*} expire_time - (optional) Timestamp to expire in window.sessionStorage - also see storage_cache comments
 */
export function cache(key, value, expire_time) {
    log(`caching value for ${key}`);

    // needs to make a copy or any place that updates the object sent to here
    // could alter the cache version.  this happened in pricing from set_price that adjusts for visible prices
    // in the object returned by cache
    let copy = $.extend({}, value);

    cache_data[key] = copy;
    
    if (expire_time) {
        storage_cache(key, value, expire_time);
    }
}


/**
 * Get from window.sessionStorage if not expired.  Requires an obj with expires property
 * 
 * @public
 * @param {string} key - Key
 */
export function storage_get(key) {
    if (!window.sessionStorage) {
        return false;
    }
    const item = window.sessionStorage.getItem(key);
    if (!item) {
        return false;
    }
    try {
        const obj = item && JSON.parse(item);
        if (!obj || obj.expires < Date.now()) {
            return false;
        }
    
        return JSON.parse(obj.value);
    }
    catch(e) {
        return false;
    }
}

/**
 * Save to window.sessionStorage with a create date.  
 * does not save functions or complex objects like Date
 * 
 * @public
 * @param {string} key - Key
 * @param {*} value - Value to cache
 * @param {*} expire_time - timestamp when to expire (required)
 */
export function storage_cache(key, value, expire_time) {
    if (!expire_time) {
        return;
    }
    const obj = {
        value: JSON.stringify(value),
        expires: expire_time,
    }
    if (expire_time && window.sessionStorage) {
        window.sessionStorage.setItem(key, JSON.stringify(obj));
    }
}