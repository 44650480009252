/** @module data/data */

import { get as get_cache, cache } from 'utils/cache';

// callbacks pass back json.data
export const get = (options) => {
    if (options == null) {
        options = {};
    }
    if (!options.endpoint) {
        return null;
    }

    options.on_done = options.on_done || (function() {});
    options.on_fail = options.on_fail || (function() {});
    if (options.loading_el && options.loading_el.length > 0) {
        console.error('The loading_el param is deprecated.  data/get() was called with the following options:');
        console.error(options);
    }

    let d = $.Deferred();
    let cache_name_prefix = options.cache_name_prefix ? options.cache_name_prefix : false;

    if (options.id && cache_name_prefix) {
        let cached = get_cache(cache_name_prefix + options.id);
        if (cached != false) {
            options.on_done({ 
                success: true, 
                cached: true, 
                data: cached
            });
            d.resolve(cached);
            return null;
        }
    }

    let url = 'api/' + options.endpoint;
    if (options.id) {
        url += '/' + options.id;
    }

    let params = [];
    if (options.fields) {
        let param = options.fields;
        if (options.fields_append) {
            param += ',' + options.fields_append;
        }
        params.push('fields=' + param);
    }
    if (options.expand) {
        let param = options.expand;
        if (options.expand_append) {
            param += ',' + options.expand_append;
        }
        params.push('expand=' + param);
    }
    if (options.include) {
        let param = options.include;
        if (options.include_append) {
            param += ',' + options.include_append;
        }
        params.push('include=' + param);
    }
    if (options.filters) {
        let param = options.filters;
        if (options.filters_append) {
            param += ',' + options.filters_append;
        }
        params.push('filters=' + param);
    }
    if (options.order) {
        params.push('order=' + options.order);
    }
    if (options.limit) {
        if (options.limit != '') {
            params.push('limit=' + options.limit);
        }
    }
    else {
        params.push('limit=' + (options.id ? 1 : 200));
    }
    if (options.group && options.group != '') {
        params.push('group=' + options.group);
    }
    if (options.adjustments) {
        params.push('adjustments=' + options.adjustments);
    }
    if (options.version) {
        params.push('version=' + options.version);
    }
    if (options.data) {
        params.push($.param(options.data));
    }

    if (params.length > 0) {
        url += '?' + params.join('&')
    }

    let url_root = (typeof __url_root !== 'undefined') ? __url_root : URL_ROOT;
    $.getJSON(url_root + url,
        function(json) {
            if (cache_name_prefix && json.success) {
                let data = !Array.isArray(json.data) ? [json.data] : json.data;
                for (let row of data) {
                    if (row.id) {
                        cache(cache_name_prefix + row.id, row);
                    }
                }
            }
            options.on_done(json);

            d.resolve(json);
        }
    )
    .fail(function(jqxhr, textStatus, error) {
        options.on_fail(jqxhr);
        d.reject(jqxhr);
    })
    return d.promise();
}

export const patch = (options) => {
    if (options == null) {
        options = {};
    }
    if (!options.endpoint || !options.id) {
        return null;
    }

    let d = $.Deferred();
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.on_done = options.on_done || (function() {});

    if (options.loading_el && options.loading_el.length > 0) {
        console.error('The loading_el param is deprecated.  data/patch() was called with the following options:');
        console.error(options);
        console.trace();
    }

    let url_root = (typeof __url_root !== 'undefined') ? __url_root : URL_ROOT;
    $.ajax({
        url: url_root + 'api/' + options.endpoint + '/' + options.id,
        data: JSON.stringify(options.data),
        method: 'PATCH',
        success: function(json) {
            if (json.success === true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options.on_done(json);

            d.resolve(json);
        },
    });

    return d.promise();
}


export const post = (options) => {
    if (options == null) {
        options = {};
    }
    if (!options.endpoint) {
        return null;
    }

    let d = $.Deferred();
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.on_done = options.on_done || (function() {});

    if (options.loading_el && options.loading_el.length > 0) {
        console.error('The loading_el param is deprecated.  data/post() was called with the following options:');
        console.error(options);
        console.trace();
    }

    let url_root = (typeof __url_root !== 'undefined') ? __url_root : URL_ROOT;
    $.ajax({
        url: url_root + 'api/' + options.endpoint,
        data: JSON.stringify(options.data),
        method: 'POST',
        success: function(json) {
            if (json.success === true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options.on_done(json);

            d.resolve(json);
        },
    });

    return d.promise();
}

export const delete_data = (options) => {
    if (options == null) {
        options = {};
    }
    if (!options.endpoint || !('id' in options)) {
        return null;
    }

    let d = $.Deferred();
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.on_done = options.on_done || (function() {});

    let url_root = (typeof __url_root !== 'undefined') ? __url_root : URL_ROOT;
    $.ajax({
        url: url_root + 'api/' + options.endpoint + '/' + options.id,
        data: JSON.stringify(options.data),
        method: 'DELETE',
        success: function(json) {
            if (json.success === true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options.on_done(json);

            d.resolve(json);
        },
    });

    return d.promise();
}