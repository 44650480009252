import { get as get_data } from 'data/data';

let d = null;

// initialize getting the token
export const init = (options) =>  {
    options = options || {};
    if (d ===  null) {
        d = $.Deferred();
    }
    else if (d.state() === 'pending') {
        return d.promise();
    }

    if (has_valid_token()) {
        let info = get();
        setup_refresh(info);
        setup_requests(info.tokens.access_token);
        d.resolve(true);
        if (options && options.on_done) {
            options.on_done();
        }
        return null;
    }

    get_token({
        on_done: function(json) {
            d.resolve(true);
            if (options && options.on_done) {
                options.on_done();
            }
        }
    });

    return d.promise();
}

// get a new token and store it
function get_token(options) {
    options = options || {};
    get_data({
        endpoint: 'auth/token',
        on_done: function(json) {
            // store token
            if (json.tokens && json.tokens.access_token) {
                setup_refresh(json, options);
                save(json);
                setup_requests(json.tokens.access_token);
            }

            if (options.on_done) {
                options.on_done();
            }
        }
    })
}

function has_valid_token() {
    let info = get();

    if (!info || info.refresh_interval <= 0) {
        return false;
    }

    if (!info.expire_time || (Date.now() > info.expire_time)) {
        return false;
    }

    if (!info.tokens || !info.tokens.access_token) {
        return false;
    }

    return true;
}

function save(json) {
    sessionStorage.setItem('ca-token-' + URL_ROOT, JSON.stringify(json));
}
function get() {
    let value = sessionStorage.getItem('ca-token-' + URL_ROOT);
    return value && JSON.parse(value);
}

// set it up for all ajax requests
function setup_requests(access_token) {
    $.ajaxSetup({
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
        }
    });
}

function setup_refresh(json, options) {
    if (json.tokens && json.tokens.access_token) {
        json.refresh_interval = (json.expires_in - (json.expires_in * .2)) * 1000;
        json.expire_time = Date.now() + json.refresh_interval;
        setTimeout(function() {
            get_token(options);
        }, json.refresh_interval);
    }
}