import { get as get_data } from 'data/data';

export const get = (options) => {
    let d = $.Deferred();
    if (options == null) {
        options = {};
    }

    options.on_done = options.on_done ? options.on_done : function() {};
    options.version = options.version ? options.version : BOOKING_VERSION;
    
    let defaults = {
        endpoint: 'tag',
        version: options.version,
        fields: 'name,slug'
    }

    return get_data($.extend(true, {}, defaults, options));
}