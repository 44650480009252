import { update_data_targets } from 'ui/data';
import { show_loading, hide_loading } from 'ui/visibility';
import { get_gift_certificate } from 'data/reservation';
import { get_cart } from 'booking-widget/v2/common/data';
import { 
    show_error, 
    format_calculated_prices, 
    format_numbers_to_currency,
    format_currency,
    string_to_number
} from 'booking-widget/v2/common/util';

import { get_calculated as _get_calculated_prices } from 'data/tour-price';

export const setup_gift_promo = (els, params) => {
    let widget_el = els.widget_el;
    let gift_promo_el = els.gift_promo_el;

    $('.bw-gift-promo-form', gift_promo_el).on('submit', function(e) {
        e.preventDefault();
        let code = $('input[name="gift_promo_code"]', $(this)).val();
        if (code == '') {
            // show error

            return;
        }

        // determine code type
        if (/^\d+$/.test(code)) {
            update_gift_certificate_prices(code, els);
        }
        else {
            update_promo_code_prices(code, els, params);
        }
    })

    $('.bw-gift-promo__remove--gift', widget_el).on('click', function(e) {
        e.preventDefault();
        clear_gift_certificate_ui(els);
        clear_saved_reservation_data(els);
    })
    $('.bw-gift-promo__remove--promo', widget_el).on('click', function(e) {
        e.preventDefault();
        update_promo_code_prices('', els, params);
    })
    
    populate_gift_promo(els, params);
}

export const update_gift_certificate_prices = (gift_code, els) => {
    let widget_el = els.widget_el;
    let gift_promo_el = els.gift_promo_el;
    let cart_el = els.cart_el;
    let gift_promo_params = els;

    show_loading(gift_promo_el);
    get_gift_certificate({
        id: gift_code,
        on_done: function(json) {
            clear_gift_certificate_ui(gift_promo_params);
            hide_loading(gift_promo_el);
            if (json.success) {
                const gc_exists = json.data.length == 1;
                if (gc_exists) {
                    let gc_amount = string_to_number(json.data[0].amount_payment_paid);
                    let total_value = string_to_number($('span[data-target="total-price"]', cart_el).text());
                    let total_price = total_value - gc_amount;
                    update_data_targets({
                        total_price: format_currency(total_price),
                        gift_code: gift_code,
                        gift_code_amount: format_currency(gc_amount),
                        gift_certificate_id: json.data[0].id ? json.data[0].id : '',
                        gift_promo_code: ''
                    }, widget_el);
                    $('.bw-selected-tour__price-list__line--gift', cart_el).show();
                    $('.bw-gift-promo__verified--gift', gift_promo_el).show();
                    $('.bw-gift-promo__errors', gift_promo_el).hide();
                    if (json.data[0].id) {
                        clear_saved_reservation_data(gift_promo_params);
                    }
                }
                else {
                    show_error($('.bw-gift-promo__errors', gift_promo_el), ['Gift certificate not found.'], false);
                }
            }
            else if (json.errors) {
                show_error($('.bw-gift-promo__errors', gift_promo_el), json.errors, false);
            }
        }
    })
}

export const clear_gift_certificate_ui = (els) => {
    let widget_el = els.widget_el;
    let cart_el = els.cart_el;
    let gift_promo_el = els.gift_promo_el;
    $('.bw-selected-tour__price-list__line--gift', cart_el).hide();
    $('.bw-gift-promo__verified--gift', gift_promo_el).hide();
    $('.bw-gift-promo__errors', gift_promo_el).hide();
    let total_value = string_to_number($('span[data-target="total-price"]', cart_el).text());
    let gc_value = string_to_number($('span[data-target="gift-code-amount"]', cart_el).text());
    if (!isNaN(gc_value)) {
        total_value += gc_value;
    }
    update_data_targets({
        total_price: format_currency(total_value),
        gift_code: 0,
        gift_code_amount: format_currency(0),
        gift_certificate_id: '',
        gift_promo_code: ''
    }, widget_el);
}

export const clear_promo_ui = (els) => {
    let widget_el = els.widget_el;
    let cart_el = els.cart_el;
    let gift_promo_el = els.gift_promo_el;
    $('.bw-selected-tour__price-list__line--promo', cart_el).hide();
    $('.bw-gift-promo__verified--promo', gift_promo_el).hide();
    $('.bw-gift-promo__errors', gift_promo_el).hide();
    let total_value = string_to_number($('span[data-target="total-price"]', cart_el).text());
    let discount_value = string_to_number($('span[data-target="discount"]', cart_el).text());
    total_value += discount_value;
    update_data_targets({
        total_price: format_currency(total_value),
        promo_code: 0,
        discount: format_currency(0),
        promo_code_id: '',
        gift_promo_code: ''
    }, widget_el);
}

export const clear_saved_reservation_data = (els) => {
    $('input[name="reservation_id"]', els.widget_el).val('');
    $('input[name="first_name"][data-contact-idx]', els.widget_el).each(function() {
        $(this)
            .removeAttr('data-passenger-id')
            .removeAttr('data-contact-id')
        ;
    });
}


export const update_promo_code_prices = (promo_code, els, params) => {
    if (typeof promo_code === 'undefined') {
        promo_code = '';
    }
    let widget_el = els.widget_el;
    let cart_el = els.cart_el;
    let gift_promo_el = els.gift_promo_el;

    show_loading(gift_promo_el);

    _get_calculated_prices({
        data: {
            trip_id: params.trip_id,
            code: promo_code,
            adult_count: params.pax_count.adult,
            child_count: params.pax_count.child,
        },
        on_done: function(json) {
            hide_loading(gift_promo_el);
            $('.bw-gift-promo__errors', gift_promo_el).hide();
            if (json.success) {
                let calculated_prices = format_calculated_prices(json);
                let gc_amount = string_to_number($('span[data-target="gift-code-amount"]', cart_el).text());
                if (isNaN(gc_amount)) {
                    gc_amount = 0;
                }
                calculated_prices.total_price = (string_to_number(calculated_prices.total_price) - gc_amount).toFixed(2);

                if (string_to_number(calculated_prices.discount) > 0) {
                    $('.bw-selected-tour__price-list__line--discount', cart_el).show();
                    $('.bw-gift-promo__verified--promo', gift_promo_el).show();
                }
                else {
                    $('.bw-selected-tour__price-list__line--discount', cart_el).hide();
                    $('.bw-gift-promo__verified--promo', gift_promo_el).hide();
                    promo_code = '';
                }

                update_data_targets($.extend({}, format_numbers_to_currency(calculated_prices), { 
                    promo_code: promo_code,
                    promo_code_id: json.meta.promo_code_id !== false ? json.meta.promo_code_id : '',
                    gift_promo_code: '',
                    submit_bar_total: calculated_prices.total_price
                }), widget_el); 
            }
            else if (json.errors) {
                if (promo_code != '') {
                    show_error($('.bw-gift-promo__errors', gift_promo_el), json.errors, false);
                }
            }
        }
    });
}

function populate_gift_promo(els, params) {
    if (params.gift_certificate_id && (parseInt(params.gift_certificate_id) || 0) != 0) {
        update_gift_certificate_prices(params.gift_certificate_id, els, params);
    }
    if (params.promo_code && params.promo_code.length > 0) {
        update_promo_code_prices(params.promo_code, els, params);
    }
}