/** @module ui/visibility */

/**
 * Show loading indicator
 *
 * @public
 * @param {!jQuery} target_el - Element(s) to display loading indicator inside of
 * @returns {void}
 */
export function show_loading(target_el) {
    if (!target_el.length) {
        return;
    }

    let loading_el = $('<div class="element-loading-overlay"></div><div class="element-loading"></div>');

    loading_el
        .attr('disable', 'disable')
        .on('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
        })
    ;
    
    target_el
        .addClass('has-loading-element')
        .append(loading_el);
}

/**
 * Hide loading indicator
 *
 * @public
 * @param {!jQuery} target_el - Element(s) to remove loading indicator from
 * @return {void}
 */
export function hide_loading(target_el) {
    if (!target_el.length) {
        return;
    }

    target_el
        .removeClass('has-loading-element')
        .find('.element-loading-overlay, .element-loading')
        .remove();
}

/**
 * Scrolls to the top of an element
 *
 * @public
 * @param {!jQuery} target_el - Element to scroll to the top of
 * @returns {void}
 */
export function scroll_to_el(target_el) {
    const buffer_top = 100 + 30; // buffer for readability

    $('html, body').animate({ scrollTop: target_el.offset().top - buffer_top }, 'slow');
}

/**
 * Scrolls to the top of an element in a div that's scrollable
 *
 * @public
 * @param {!jQuery} inner_el
 * @param {!jQuery} target_el
 * @returns {void}
 */
export function scroll_to_inner(inner_el, target_el) {
    let buffer_top = target_el.position().top + 30; // nice buffer for readability

    target_el.animate({ scrollTop: target_el.scrollTop() - buffer_top + inner_el.offset().top }, 'slow');
}

/**
 * Adds a transparency to an RGB value
 *
 * @public
 * @param string
 * @return string
 */
export function rgb_add_transparency(color_str) {
    let re = /(rgb)\(([0-9]+),\s+([0-9]+),\s+([0-9]+)/; //rgb regex to grab individual color vals
    let replace_with = 'rgba($2,$3,$4,0.75';
    let rgb = color_str;

    return rgb.replace(re, replace_with);
}

export function show_disabled(target_el) {
    if (!target_el.length) {
        return;
    }

    let disabled_el = $('<div class="element-disabled-overlay"></div>');

    disabled_el
        .attr('disable', 'disable')
        .on('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
        })
    ;
    
    target_el
        .addClass('has-disabled-element')
        .append(disabled_el);
}


export function hide_disabled(target_el) {
    if (!target_el.length) {
        return;
    }

    target_el
        .removeClass('has-disabled-element')
        .find('.element-disabled-overlay')
        .remove();
}

// use to lazy load images or trigger a callback for the queried items.
export function observe_items(options) {
    let on_intersect = 'on_intersect' in options ? options.on_intersect : function() {};
    let lazy_selector = 'selector' in options ? options.selector : '.lazy';

    let io = new IntersectionObserver(function(entries) {
        entries.forEach((entry) => {
            // set image source only when it is in the viewport
            if (entry.isIntersecting) {
                const target_dom = entry.target;
                if (on_intersect) {
                    on_intersect(target_dom);
                }
                else {
                    // by default, set image source from the dataset
                    target_dom.src = target_dom.dataset.src;
                }
    
                // when image is loaded, we do not need to observe it any more
                io.unobserve(target_dom);
            }
        })
    });

    document.querySelectorAll(lazy_selector).forEach(function(element) { 
        io.observe(element);
    });

    return io;
}


export function show_fullscreen_overlay(target_el) {
    if (!target_el.length) {
        return;
    }

    if ($(".element-fullscreen-overlay", target_el).length > 0) {
        return;
    }

    let el = $('<div class="element-fullscreen-overlay"></div>');

    el
        .attr('disable', 'disable')
        .on('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
        })
    ;
    
    target_el
        .addClass('has-fullscreen-overlay')
        .append(el);
}

export function hide_fullscreen_overlay(target_el) {
    if (!target_el.length) {
        return;
    }

    target_el
        .removeClass('has-fullscreen-overlay')
        .find('.element-fullscreen-overlay')
        .remove();
}