import { get as get_cache, cache } from 'utils/cache';
import log from 'utils/log';

import { show_loading, hide_loading } from 'ui/visibility';

import { apiselect as reservation_apiselect } from 'data/reservation';
import { get as get_data, post as post_data, patch as patch_data } from 'data/data';

import { to_system_date, to_system_date_utc } from 'utils/date';

/**
 * Private cache of Discounts
 * @private
 * @type {?object[]}
 */
let discounts_list = null;


export const table_columns = {
    'index': 0,
    'is_modified': 1,
    'tour': 2,
    'vessel': 3,
    'trip_time': 4,
    'name': 5,
    'agency': 6,
    'group': 7,
    'hotel': 8,
    'reservation_id': 9,
    'adult_count': 10,
    'child_count': 11,
    'waiver': 12,
    'checkin': 13,
    'payment': 14,
    'notify': 15,
    'modified_time': 16,
    'is_primary': 17,
}

// export const table_column_names = [
//     { name: 'index',            targets: 0 },
//     { name: 'tour',             targets: 1 },
//     { name: 'vessel',           targets: 2 },
//     { name: 'trip_time',        targets: 3 },
//     { name: 'name',             targets: 4 },
//     { name: 'agency',           targets: 5 },
//     { name: 'reservation_id',   targets: 6 },
//     { name: 'adult_count',      targets: 7 },
//     { name: 'child_count',      targets: 8 },
//     { name: 'waiver',           targets: 9 },
//     { name: 'checkin',          targets: 10 },
//     { name: 'payment',          targets: 11 },
// ];

// options.tour_vessel_id
// options.loading_el (optional)
// options.on_done (optional)
export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let default_order = 'is_placeholder,contact.last_name,contact.first_name,id';
    options.filters = options.filters || {};
    let is_waitlist = options.filters.waitlisted;
    
    // build filters from options.filters
    let filters = '';
    if (options.filters.raw) {
        filters += options.filters.raw;
    }
    else {
        if (options.filters.reservation_id) {
            filters += 'reservation_id.eq(' + options.filters.reservation_id + '),';
            default_order = '!is_primary_passenger,!is_active,' + default_order;
        }
        if (options.filters.status) {
            filters += 'status.eq(' + options.filters.status + '),';
        }
        if (options.filters.tour_vessel_id) {
            if (options.filters.include_cancelled) {
                filters += 'reservation.trip_id.eq(' + options.filters.tour_vessel_id + '),';
            }
            else {
                filters += 'reservation.trip_id.eq(' + options.filters.tour_vessel_id + '),reservation.is_active.eq(1),';   
            }
            default_order = '!is_primary_passenger,' + default_order;
        }
        if (options.filters.contact_id) {
            filters += 'contact_id.eq(' + options.filters.contact_id + '),';
        }
        if (options.filters.vessel_id) {
            filters += 'reservation.vessel.id.eq(' + options.filters.vessel_id + '),';
        }
        if (options.filters.not_tour_vessel_id) {
            filters += 'reservation.trip_id.neq(' + options.filters.not_tour_vessel_id + '),reservation.is_active.eq(1),';
            default_order = '!is_primary_passenger,' + default_order;
        }
        if (options.filters.tour_id) {
            if (is_waitlist) {
                filters += 'reservation.is_waitlisted.eq(1),';
                filters += 'reservation_tour.tour_id.eq(' + options.filters.tour_id + '),';
                filters += 'reservation.created_time.lte(' + to_system_date_utc(options.filters.tour_local_dt, true) + '),';
                filters += 'reservation.waitlist_end_eod_time.gte(' + options.filters.tour_start_time + '),';
            }
            else {
                filters += 'reservation.tour.id.eq(' + options.filters.tour_id + '),';
            }
        }
        filters = filters.replace(/,+$/, "");
    }
    options.order = options.order || default_order;
    options.filters = filters;

    log('getting tour vessel passengers data for ' + options.tour_vessel_id);

    let fields = 'id,tax,fees,base,is_checked_in,is_boarded,passenger_type,is_active,has_waiver,status,contact_id,' + 
            'commission,bill,discount,discount_code_id,is_primary_passenger,is_placeholder,is_child,food_preference,hotel_id,' +
            'contact.id,contact.first_name,contact.last_name,contact.email,contact.phone,' +
            'agency.name,agency.payment_type,' +
            reservation_apiselect + ',reservation.created_by_source,' +
            'hotel.id,hotel.name,' +
            'tour.name,tour.id,' +
            'vessel.name,vessel.id,' +
            'reservation_group.name,' +
            'tour_vessel.start_time,tour_vessel.checkin_time,tour_vessel.tour_time,tour_vessel.is_active,tour_vessel.is_charter,tour_vessel.status,' +
            'passenger_attachment.name,passenger_attachment.type,passenger_attachment.created_time,passenger_attachment.id';

    let expand = 'reservation,reservation_transaction,transaction,agency,passenger_attachment,hotel,tour_vessel,tour,vessel,reservation_group';

    if (is_waitlist) {
        fields += ',reservation_tour.id';
        expand += ',reservation_tour';
    }
    let defaults = {
        endpoint: 'passenger',
        cache_name_prefix: 'passenger-',
        expand: expand,
        include: 'passenger_attachment',
        fields: fields,
        order: 'is_placeholder,contact.last_name,contact.first_name,id',
        limit: 1000,
    }

    return get_data($.extend(true, {}, defaults, options));
}

// get by tour vessel and cache by tour vessel
export const get_by_tour_vessel = (options) => {
    if (options == null) {
        options = {};
    }
    if (!('tour_vessel_id' in options)) {
        return null;
    }

    let options_on_done = options.on_done || (function() {});
    let tour_vessel_id = options.tour_vessel_id;
    let include_cancelled = options.include_cancelled || false;
    let cache_name = 'passengers-in-tour-vessel-' + tour_vessel_id;

    let json = build_cache_from_tour_vessel(get_cache(cache_name));
    if (json != false) {
        options_on_done(json);
        return null; // needed if used in jquery when/then
    }

    delete options.on_done;
    delete options.tour_vessel_id;

    let new_options = {
        filters: {
            tour_vessel_id: tour_vessel_id,
            include_cancelled: include_cancelled
        },
        on_done: function(json) {
            let ids = [];
            for (let row of json.data) {
                ids.push(row.id);
            }
            cache(cache_name, ids);

            options_on_done(json);
        }
    };
    return get($.extend(true, {}, new_options, options));
}


export const post = (options) => {
    if (options == null) {
        options = {};
    }

    let params = {
        endpoint: 'passenger'
    }

    return post_data($.extend(true, {}, params, options));
}

export const update_status = (options) => {
    if (options == null) {
        return false;
    }

    let options_on_done = options.on_done || (function() {});
    let endpoint = options.status || '';
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});

    let supported_endpoints = ['cancel', 'unnoshow', 'no_show', 'cancelled_at_dock', 'uncancelled_at_dock', 'active', 'board', 'unboard'];
    if (supported_endpoints.indexOf(endpoint) == -1) {
        return;
    }

    delete options.on_done;
    delete options.status;

    log(endpoint + ' passenger ' + options.passenger_id);

    let data = {}
    if (options.status_reason) {
        data = {status_reason: options.status_reason};
    }
    if (options.source) {
        data['source'] = options.source;
    }

    let defaults = {
        endpoint: 'passenger/' + options.passenger_id + '/' + endpoint,
        data: data,
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }
    return get_data($.extend(true, {}, defaults, options));
}

export const patch = (options) => {
    if (options == null) {
        options = {};
    }

    let params = {
        endpoint: 'passenger'
    }

    return patch_data($.extend(true, {}, params, options));

}

// sends notification
export const send_notification = (options) => {
    if (options == null) {
        options = {};
    }

    options.on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.passenger_id = options.passenger_id || null;
    options.loading_el = options.loading_el || $(false);
    log('sending notifiation to passenger ' + options.passenger_id);

    show_loading(options.loading_el);

    $.ajax({
        method: 'GET',
        url: __url_root + 'api/passenger/' + options.passenger_id + '/notification_confirmation',
        success: function (json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json.errors);
            }

            options.on_done();

            hide_loading(options.loading_el);
        },
    });

}


/**
 * Populate dropdown with list of passsenger discounts
 *
 * @public
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
export const populate_discounts_list = (options) => {
    options.context_el = options.context_el || $('body');

    if (discounts_list !== null) {
        load_discounts_list(options);
        return;
    }

    let uri = __url_root + 'api/discount_code?' +
        'fields=id,name,value,value_type,is_active,is_billable,is_manual,applies_to' +
        '&order=discount_code.name' +
        '&limit=500'
    ;

    uri += '&filters=discount_code.is_active.eq(1)';

    if (options.selected_discount_id != null) {
        uri += ',discount_code.id.oeq(' + options.selected_discount_id + ')';
    }
    $.getJSON(
        uri,
        function (json) {
            // cache the whole list
            discounts_list = json.data;

            load_discounts_list(options);
        }
    );
}

export const self_checkin_key = (options) => {
    let defaults = {endpoint: 'passenger/' + options.passenger_id + '/self_checkin_key'};
    return get_data($.extend(true, {}, defaults, options));
}

/**
 * Populate target dropdown w/ cached passsenger discounts
 *
 * @private
 * @param {?jQuery} context_el - Element to search within for target <select>. Defaults to $('body').
 * @returns {void}
 */
function load_discounts_list(options) {
    let select_el = $('select[data-target=discount-code-id]', options.context_el);
    options.selected_discount_id = options.selected_discount_id || null;
    
    if (!select_el.length) {
        return;
    }

    select_el
        .empty()
        .append('<option data-discount-rate="0" data-discount-rate-type="" data-discount-value="0" data-discount-value-entity="" data-discount-is-manual="0" value=""></option>');

    if (!discounts_list) {
        return;
    }

    for (let row of discounts_list) {
        let val = Number(row.value);
        let label = '';
        if (row.value_type == 'percent') {
            label = val + '%'
        }
        else if (row.value_type == 'dollar') {
            label = '$' + val;
        }
        select_el.append(`
            <option
                data-discount-rate=${val}
                data-discount-rate-type=${row.value_type}
                data-discount-is-manual=${row.is_manual}
                data-discount-applies-to=${row.applies_to}
                data-discount-is-billable=${row.is_billable}
                value="${row.id}">${row.name} (${label})
            </option>`);
    }
}

function build_cache_from_tour_vessel(tv_data) {
    if (!tv_data) {
        return false;
    }

    let ids = Object.values(tv_data);
    if (ids.length == 0) {
        return false;
    }

    let return_data = { success: true, cached: true, data: []};

    // each row is just an id
    for (let id of ids) {
        let row = get_cache('passenger-' + id);
        if (!row) {
            return false;
        }
        return_data.data.push(row);
    }

    return return_data;
}

export const get_passenger_name = (passenger, primary_passenger, delimiter) => {
    let passenger_name = '';
    let _delimiter = delimiter ? delimiter : ' ';
    if (passenger_has_name(passenger)) {
        passenger_name = passenger.contact[0].first_name + _delimiter + passenger.contact[0].last_name;
    }
    else if (primary_passenger) {
        let primary_name = primary_passenger.contact[0].first_name + _delimiter + primary_passenger.contact[0].last_name;
        if (primary_name != '') {
            passenger_name = '(guest of) ' + primary_name;
        }
    }
    return passenger_name;
}

export const passenger_has_name = (passenger) => {
    return (passenger && passenger.contact && passenger.contact[0].last_name != null && passenger.contact[0].first_name != null);
}