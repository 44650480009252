
import { 
    MODAL_TEMPLATE,
    CLOSE_MODAL_TEMPLATE,
    TOUR_CARD_TAG,
    FS_LOADING_TEMPLATE
} from 'booking-widget/v2/templates/shared';
import { CONTACT_US_URL, LOGO_URL } from 'booking-widget/v2/common/data';
import {
    init as setup_modal,
    show as show_modal
} from 'ui/modal';
import { 
    show_loading as _show_loading, 
    hide_loading as _hide_loading, 
} from 'ui/visibility';

import { convertISOtoDate, to_system_date, } from 'utils/date';
import { esc_html } from 'utils/strings';

import { startOver, is_showing_full_screen } from 'booking-widget/v2/bw'

export const exclusion_string = (toddlers_allowed, pregnant_allowed) => {
    let str = '';

    if (toddlers_allowed == 0) {
        str += 'Minimum age 6 years. ';
    }
    else {
        str += 'Minimum age 3 years. ';
    }
    if (pregnant_allowed == 2) {
        str += 'Must be under 26 weeks of pregnancy. ';
    }
    else if (pregnant_allowed == 0) {
        str += 'Not suitable for pregnant woman. ';
    }
    return str;
}

export const setup_maintenance_trigger = (opts) => {
    $(document).ajaxError(function( event, jqxhr, settings, exception ) {
        let error_msg = opts.server_error_message ? opts.server_error_message : 'Please try again later.';
        if ( jqxhr.status == 503 && jqxhr.responseJSON && jqxhr.responseJSON.errors && jqxhr.responseJSON.meta.error_code && jqxhr.responseJSON.meta.error_code == 5000) {
            $(document).trigger('bw-maintenance-error', {
                status: jqxhr.status, 
                jqxhr:jqxhr,
                message: error_msg,
            });
        }
        else if (jqxhr.status == 0 || jqxhr.status > 299) {
            $(document).trigger('bw-server-error', {
                status: jqxhr.status, 
                jqxhr:jqxhr,
                message: error_msg,
            });
        }
    });
    

    $(document).on('bw-maintenance-error bw-server-error', function(e, params) {
        if (!is_showing_full_screen()) {
            return;
        }
        const modal_id = 'server-modal';
        if (!$('body').hasClass('bw--server-notice')) {
            $('body')
                .addClass('bw--server-notice')
                .attr('data-server-status', params.status)
            ;
            let error_msg = params.message;
            setup_modal({
                html: set_placeholders(MODAL_TEMPLATE, { modal_id: modal_id }),
                on_shown: function(e, params) {
                    $('.bw-modal__content', '#' + modal_id)
                        .empty()
                        .append('<h1 class="bw-mb--15 bw-text-center">Oops!</h1>')
                        .append($('<p class="bw-mb--30 bw-text-center" />').text(error_msg))
                        .append(CLOSE_MODAL_TEMPLATE)
                        .css({
                            maxWidth: '500px',
                            margin: '0 auto'
                        })
                    ;
                },
                on_hidden: startOver
            });
            show_modal($('#' + modal_id));
        }
    });
}

export const filter_data = (data, p) => {
    let params = $.extend({
        id: false,
        tour_id: false,
        not_tour_id: false,
        trip_id: false,
        vessel_type: false,
        only_on_start_time: false, // hst
        only_after_start_time: false, // hst
        min_seats_available: false,
        unavailable: false, // { adult: xx, child: yy, toddler: zz }
        ampm: false,
    }, p);
    
    const total_pax_count = params.pax_count ? (params.pax_count.adult + params.pax_count.child + params.pax_count.toddler) : (params.min_seats_available ? params.min_seats_available : 0);
    let results = data.filter(function(row) {
        if (params.id) {
            if (row.id != params.id) {
                return false;
            }
        }
        if (params.tour_id) {
            if (!Array.isArray(params.tour_id)) {
                params.tour_id = [params.tour_id];
            }
            if (!params.tour_id.map(String).includes(row.tour_id)) {
                return false;
            }
        }
        if (params.not_tour_id) {
            if (!Array.isArray(params.not_tour_id)) {
                params.not_tour_id = [params.not_tour_id];
            }
            if (params.not_tour_id.includes(row.tour_id)) {
                return false;
            }
        }
        if (params.trip_id) {
            if (row.trip_id != params.trip_id) {
                return false;
            }
        }

        if (params.vessel_type) {
            if (row.vessel[0].vessel_type != params.vessel_type) {
                return false;
            }
        }
        if (params.only_on_start_time) {
            // both row.start_time and only_on_start_time are HST, so ok to compare.
            let row_dt = convertISOtoDate(row.start_time);
            let start_dt = convertISOtoDate(params.only_on_start_time + ' 00:00:00');
            if (to_system_date(row_dt) != to_system_date(start_dt)) {
                return false;
            }
        }
        if (params.only_after_start_time) {
            // both row.start_time and only_after_start_time are HST, so ok to compare.
            let row_dt = convertISOtoDate(row.start_time);
            let start_dt = convertISOtoDate(params.only_after_start_time + ' 00:00:00');
            if (row_dt < start_dt) {
                return false;
            }
        }

        if (params.min_seats_available && !params.only_unavailable) {
            if (row.seats_available < params.min_seats_available) {
                return false;
            }
        }
        if (params.ampm) {
            let row_dt = convertISOtoDate(row.start_time);
            if (params.ampm == 'am') {
                if (row_dt.getHours() >= 12) {
                    return false;
                }
            }
            else if (params.ampm == 'pm') {
                if (row_dt.getHours() < 12) {
                    return false;
                }
            }
        }

        if (row.vessel && row.vessel[0].capacity_max < total_pax_count) {
            return false;
        }

        // this needs to be the last check
        if (params.only_unavailable || params.only_available) {
            let is_unavailable = false;
            let vessel = row.vessel[0];
            if (row.seats_available < total_pax_count) {
                is_unavailable = true;
            }
            if (params.charter_available) {
                if (row.seats_available != row.vessel[0].capacity_max) {
                    is_unavailable = true;
                }
            }

            if (vessel.toddlers_allowed != 1 && params.pax_count.toddler && params.pax_count.toddler > 0) {
                is_unavailable = true;
            }

            let row_dt = convertISOtoDate(row.start_time);
            if (Date.now() > (parseInt(row.cutoff_timestamp * 1000) || row_dt.getTime())) {
                is_unavailable = true;
            }

            if (params.only_unavailable) {
                return is_unavailable;
            }

            if (params.only_available && is_unavailable) {
                return false;
            }
        }

        return true;
    });

    return results;
}


// return a data object for the ui to use
export const format_calculated_prices = (prices_json) => {
    let prices = {
        adult: {
            base: null,
            tax: null,
            fees: null,
            total_base: 0,
            total_discount: 0
        },
        child: {
            base: null,
            tax: null,
            fees: null,
            total_base: 0,
            total_discount: 0
        }
    };

    let total_taxes = 0;
    let total_fees = 0;
    for (let i = 0; i < prices_json.data.length; i++) {
        let passenger_prices = prices_json.data[i];
        let price_type = (passenger_prices.is_child != 1) ? 'adult' : 'child';

        if (prices[price_type].base === null) {
            prices[price_type].base = Number(passenger_prices.base);
            prices[price_type].tax = Number(passenger_prices.tax);
            prices[price_type].fees = Number(passenger_prices.fees);
        }

        prices[price_type].total_base += Number(passenger_prices.base);
        prices[price_type].total_discount += Number(passenger_prices.discount);
        total_taxes += Number(passenger_prices.tax);
        total_fees += Number(passenger_prices.fees);
    }

    let total_taxes_fees = total_taxes + total_fees;
    let total_discount = prices.adult.total_discount + prices.child.total_discount;
    let total_base = prices.adult.total_base + prices.child.total_base;

    return {
        promo_code_id: prices_json.meta.promo_code_id,
        promo_code: prices_json.meta.code,

        adult_price_base: Number(prices.adult.base).toFixed(2),
        adult_price_tax: Number(prices.adult.tax).toFixed(2),   
        adult_price_fees: Number(prices.adult.fees).toFixed(2),

        child_price_base: Number(prices.child.base).toFixed(2),
        child_price_tax: Number(prices.child.tax).toFixed(2),
        child_price_fees: Number(prices.child.fees).toFixed(2),

        total_price_base: total_base.toFixed(2),

        adult_price_total: Number(prices.adult.total_base).toFixed(2),
        child_price_total: Number(prices.child.total_base).toFixed(2),
        taxes: Number(total_taxes).toFixed(2),
        fees: Number(total_fees).toFixed(2),
        taxes_fees: Number(total_taxes_fees).toFixed(2),
        discount: Number(total_discount).toFixed(2),
        
        subtotal: Number(total_base - total_discount).toFixed(2),
        total_price: Number(total_base + total_taxes_fees - total_discount).toFixed(2)
    }
}

// take in an object of numbers and non-numbers and return formatted values with $ and ,.
// for the whitelisted keys.
export const format_numbers_to_currency = (calculated_prices) => {
    const currency_keys = [
        'adult_price_base',
        'adult_price_tax',
        'adult_price_fees',
        'child_price_base',
        'child_price_tax',
        'child_price_fees',
        'total_price_base',
        'adult_price_total',
        'child_price_total',
        'taxes',
        'fees',
        'taxes_fees',
        'discount',
        'subtotal',
        'total_price',
    ];
    let cp = $.extend({}, calculated_prices);
    for (let key of currency_keys) {
        if (key in cp) {
            cp[key] = format_currency(cp[key]);
        }
    }

    return cp;
}

export const set_placeholders = (template, replacements) => {
    // default replacements
    let _replacements = $.extend({ 
        logo_url: LOGO_URL,
        contact_us_url: CONTACT_US_URL,
    }, replacements);
    for (const key in _replacements) {
        const regex = new RegExp(`\\[${key}\\]`, 'g');
        template = template.replace(regex, _replacements[key]);
    }
    return set_placeholders_selected_tour(template, _replacements);
}

export const set_placeholders_selected_tour = (template, replacements) => {
    if (replacements.selected_tour_params && typeof replacements.selected_tour_params === 'object') {
        let params = replacements.selected_tour_params;
        let tv_data = params.tv_data;
        let tour = params.tour;

        let defaults = {
            message: params.message,
            checkin: '',
            location: '',
            directions_uri: '',
            tour_name: '',
            highlights: '',
            use_tour_info_as_highlights: false,
            adult_count: '',
            child_count: '',
            adult_label: '',
            child_label: '',
            adult_price_base: '',
            child_price_base: '',
            adult_price_total: '',
            child_price_total: '',
            discount: '',
            taxes_fees: '',
            gift_code_amount: '',
            total_price: '',
            tour_image_url: '',
            passenger_names: '',
            payment_info_html: params.payment_info_html ? params.payment_info_html : '',
            contact_label: '',
            primary_name: params.primary_name ? params.primary_name : '',
            primary_email: params.primary_email ? params.primary_email : '',
            primary_phone: params.primary_phone ? params.primary_phone : '',
            guest_count: params.guest_count ? params.guest_count : '',
            gc_form: params.gc_form ? params.gc_form : '',
            notes: params.notes ? 'Notes: ' + params.notes : '',
        }
        
        let _replacements = {};
        if (tv_data) {
            let checkin_dt = convertISOtoDate(tv_data.start_time);
            checkin_dt.setMinutes(checkin_dt.getMinutes() - tour.checkin_minutes);
            let checkin = checkin_dt.toLocaleTimeString('en-US', {
                hour:'numeric', 
                minute:'2-digit'
            });
            let location = (tv_data.location && tv_data.location.length > 0) ? tv_data.location[0].name : '';
            let directions_uri = (tv_data.location && tv_data.location.length > 0) ? tv_data.location[0].map_uri : '';
            let adult_count = params.adult_count;
            let adult_label = params.adult > 1 ? 'Adults @' : 'Adult @';
            let adult_price_base = '$' + params.prices.adult_price_base;
            let contact_label = 'Contact Info';
            if (params.is_charter) {
                adult_count = '';
                adult_label = 'Entire Boat Charter Fee';
                adult_price_base = '';
                contact_label = 'Contact and Notes';
            }
            let highlights = '';
            if (params.use_tour_info_as_highlights) {
                // tour time
                let start_dt = convertISOtoDate(tv_data.start_time);
                let date_str = start_dt.toLocaleDateString('en-US', { weekday: 'short' });
                date_str += ' ' + start_dt.toLocaleDateString('en-US', { 
                    month: 'short',
                    year: 'numeric',
                    day: 'numeric'
                });

                // passengers, not enough room for "children"
                let pax_count = adult_count + ' adult';
                if (params.child_count) {
                    pax_count += '/' + params.child_count + ' child';
                }

                highlights = tour_highlights([date_str,'Check-in: ' + checkin,pax_count, params.extra_tag_html]);
            }
            else {
                highlights = tour_highlights(tour.highlights_bw);
            }
            _replacements = {
                checkin: checkin,
                location: location,
                directions_uri: directions_uri ? encodeURI(directions_uri) : '',
                tour_name: tour.name,
                highlights: highlights,
                adult_count: adult_count,
                child_count: params.child_count,
                adult_label: adult_label,
                child_label: (params.child_count && params.child_count > 1) ? 'Children @' : 'Child @',
                adult_price_base: adult_price_base,
                child_price_base: format_currency(params.prices.child_price_base),
                adult_price_total: format_currency(params.prices.adult_price_total),
                child_price_total: format_currency(params.prices.child_price_total),
                discount: format_currency(params.prices.discount),
                taxes_fees: format_currency((Number(params.prices.taxes) + Number(params.prices.fees)).toFixed(2)),
                gift_code_amount: format_currency(params.prices.gift_code_amount),
                total_price: format_currency(params.prices.total_price),
                tour_image_url: encodeURI(tour.bw_v2_image_url),
                passenger_names: params.passenger_names ? params.passenger_names.join('<br>') : '',
                contact_label: contact_label
            };
        }
        else {
            const prices = params.prices;
            const amount_only = Number(prices.amount_only ? prices.amount_only : 0);
            const amount_only_taxes = Number(prices.amount_only_taxes ? prices.amount_only_taxes : 0);
            const amount_only_fees = Number(prices.amount_only_fees ? prices.amount_only_fees : 0);
            _replacements = {
                adult_label: 'Subtotal',
                adult_count: '',
                adult_price_base: '',
                adult_price_total: format_currency(amount_only),
                contact_label: 'Contact and Notes',
                taxes_fees: format_currency((amount_only_taxes + amount_only_fees).toFixed(2)),
                total_price: format_currency((amount_only + amount_only_taxes + amount_only_fees).toFixed(2)),
            };
        }
        return set_placeholders(template, $.extend({}, defaults, _replacements));
    }

    return template;
}

export const tour_highlights = (mixed, last_tag_addon_html) => {
    if (!mixed) {
        return '';
    }

    let tags_arr = mixed;
    if (!Array.isArray(mixed)) {
        tags_arr = mixed.split(',').map(s => s.trim());
    }
    let tags_html = '';
    for (let i = 0; i < tags_arr.length; i++) {
        let str = tags_arr[i];
        if (last_tag_addon_html && i == tags_arr.length - 1) {
            str += last_tag_addon_html;
        }
        tags_html += set_placeholders(TOUR_CARD_TAG, { tag: str });
    }

    return tags_html;
}

export const update_day_el = (day_el, date, disabled, forceFormat) => {
    let format = forceFormat ? forceFormat : 'short';
    let dayFormat = format != 'short' ? '2-digit' : 'numeric';
    let date_str = date.toLocaleDateString('en-US', { day: dayFormat });
    let day = date.toLocaleDateString('en-US', { weekday: format });
    // day.substring(0, 2);
    let month = date.toLocaleDateString('en-US', { month: format });
    $('.day', day_el).text(day);
    $('.date', day_el).text(date_str);
    $('.month', day_el).text(month);
    day_el.attr('data-date', to_system_date(date));
    if (disabled) {
        day_el.removeAttr('href');
        day_el.attr('disabled', 'disabled');
    }
}

export const scroll_to = (el) => {
    $([document.documentElement, document.body]).animate({
        scrollTop: el.offset().top - 15
    }, 300);
}

export const clear_errors = () => {
    $('input.bw-error').removeClass('bw-error');
}

export const show_error = (error_el, errors, scrollTo) => {
    let error_msgs = [];
    let input_names = [];
    if (typeof errors === 'object') {
        error_msgs = Object.values(errors);
        input_names = Object.keys(errors);
    }
    else {
        if (!Array.isArray(errors)) {
            error_msgs = [errors];
        }
        else {
            error_msgs = errors;
        }
    }
    scrollTo = typeof scrollTo !== 'undefined' ? scrollTo : true;
    const html = '<ul><li>' + error_msgs.filter(Boolean).join('</li><li>') + '</li></ul>';
    $('.bw-errors__list', error_el).html(html);
    error_el.show();
    if (scrollTo) {
        const bw_header_height = $('.bw-header').height();
        $('.booking-widget-container').animate({
            scrollTop: error_el.position().top - bw_header_height
        }, 500);
    }
    for (let i = 0; i < input_names.length; i++) {
        $('input[name="' + input_names[i] + '"]').addClass('bw-error');
    }
    
}

export const show_loading = (target_el, is_full_screen) => {
    let el = is_full_screen ? $('body') : target_el;
    if (is_full_screen) {
        el.addClass('bw-full-screen-loading');
    }
    _show_loading(el);
    if (is_full_screen) {
        $('.element-loading', el).append(FS_LOADING_TEMPLATE);
    }
}

export const hide_loading = (target_el, is_full_screen, _delay) => {
    let el = is_full_screen ? $('body') : target_el;
    let delay = is_full_screen ? (typeof delay === 'undefined' ? 0 : _delay) : 0;
    if (delay > 0) {
        setTimeout(function() {
            el.removeClass('bw-full-screen-loading');
            _hide_loading(el);
        }, delay);
    }
    else {
        el.removeClass('bw-full-screen-loading');
        _hide_loading(el);
    }
}

export const setup_selected_tour_day = (context_el, tv_start_time, clickable) => {
    let date_el = $('.bw-date-item', context_el);
    let start_dt = convertISOtoDate(tv_start_time);
    update_day_el(date_el, start_dt, !clickable, 'long');
}

export const format_currency = (value) => {
    if (typeof value === 'undefined' ) {
        return '';
    }
    const val = (typeof value === 'string') ? value.replace(/[^0-9.-]+/g, "") : value;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return formatter.format(val);
}

// removes non-numeric characters like "$" before using Number()
export const string_to_number = (str) => {
    return Number(str.replace(/[^0-9.-]+/g, ""));
}

export const get_scrollbar_width = () => {
    let outer_el = $('<div>').css({
        width: '100px',
        height: '100px',
        overflow: 'scroll',
        position: 'absolute',
        top: '-9999px',
    }).appendTo('body');

    const scrollbar_width = outer_el[0].offsetWidth - outer_el[0].clientWidth;

    outer_el.remove();

    return scrollbar_width;
}

export const split_full_name = (full_name) => {
    if (!full_name) {
        return {};
    }
    full_name = full_name.trim();
    const index = full_name.indexOf(" ");

    // If there's no space, assign the entire name as the first name
    if (index === -1) {
        return { first_name: full_name, last_name: "" };
    }

    // Extract first name and last name
    const first_name = full_name.substring(0, index);
    const last_name = full_name.substring(index + 1);

    return { first_name, last_name };
}