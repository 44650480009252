/** @module utils/log */

/**
 * How many lines to allow in the nerd log
 *
 * @private
 * @type {!number}
 */
let 
    tech_detail_limit = 250,
    container_el = $('.nerd-container'),
    content_el = container_el.find('.content')
;



/**
 * Set the max number of lines allowed in nerd log
 *
 * @public
 * @param {!number} num - Number of lines to allow
 * @returns {void}
 */
export function set_visible_limit(num) {
    tech_detail_limit = num;
}

/**
 * Log a message
 *
 * @public
 * @param {string} text - Message to log
 * @returns {void}
 */
export default function log(text) {
    if (!content_el.length) {
        return;
    }
    
    content_el
        .append(`<div>
                    <span class="timestamp">${(new Date()).toTimeSystem()}</span>
                    <span class="text">${text}</span>
                </div>`)
    ;

    container_el
        .scrollTop(content_el.height())
    ;

    // if we've exceeded the limit of logs, trim the oldest
    let nerds_el = content_el.find('div');
    if (nerds_el.length > tech_detail_limit) {
        nerds_el.slice(0, nerds_el.length - tech_detail_limit).remove();
    }
}
