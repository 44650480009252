export const load_js = (options) => {
    let opts = $.extend({
        is_loaded: function(){ return false; },
        on_ready: function() {},
    }, options);

    if (opts.is_loaded()) {
        if (opts.on_ready) {
            opts.on_ready();
        }
        return;
    }
    
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = script.onreadystatechange = function() {
        if (script.readyState) {
            if (script.readyState === 'complete' || script.readyState === 'loaded') {
                script.onreadystatechange = null;
                if (opts.on_ready) {
                    opts.on_ready();
                }
            }
        } 
        else {
            if (opts.on_ready) {
                opts.on_ready();
            }
        }
    };
    script.src = opts.url;
    head.appendChild(script);
}