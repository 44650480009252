/** @module data/tour */
import { get as get_data } from 'data/data';

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'tour',
        fields: 'id,name,description',
        order: 'display_order'
    }

    return get_data($.extend(true, {}, defaults, options));
}

/**
 * Private local cache of tours
 * @private
 * @type {?object[]}
 */
let tours_list = null;

/**
 * Default options
 * @private
 * @type {object}
 */
const defaults = {
    context_el: $('body'),
    default: 'Select a tour',
    value: null,
};

/**
 * Populates dropdown(s) with tours in cache
 *
 * @private
 * @param {!object} options
 * @param {jQuery} options.context_el - Element to search within for target <select>
 * @param {string} options.default - Default option text
 * @param {string} options.value - Selected value
 * @returns {void}
 */
export const load = (options) => {
    options = $.extend({}, defaults, options);

    let select_el = $('select[data-target=list-tour]', options.context_el);

    if (!select_el.length) {
        return;
    }

    select_el
        .empty()
        .append(`<option value="">${options.default}</option>`);

    if (!options.tours_list) {
        return;
    }

    for (let row of options.tours_list) {
        if (options.exclude_inactive && row.is_active != 1 && options.value != row.id) {
            continue;
        }
        select_el.append(`<option value="${row.id}">${row.name}</option>`);
    }

    if (options.value) {
        select_el.val(options.value);
    }
}
