import { get as get_data } from 'data/data';

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'tour_price',
        fields: 'base,fees,tax,tour_id,is_child'
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const get_calculated = (options) => {
    if (options == null) {
        options = {};
    }

    if (!('data' in options) || !('trip_id' in options.data)) {
        return;
    }

    let defaults = {
        endpoint: 'tour_price/calculate_billing_parts',
        data: {
            tour_vessel_id: options.data.trip_id
        }
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const get_future_prices = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'tour_price/future_prices',
    }

    return get_data($.extend(true, {}, defaults, options));
}