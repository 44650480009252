export const init = (selector, bw_events) => {
    if (!window.intlTelInput) {
        $(document)
            .off('bw-intltelinput-loaded')
            .on('bw-intltelinput-loaded', function() {
                _init(selector);
            });
    }
    else {
        _init(selector);
    }
}

export const is_possible_number = (input_el) => {
    return window.intlTelInputGlobals.getInstance(input_el[0]).isPossibleNumber();
}

export const get_number = (input_el) => {
    return window.intlTelInputGlobals.getInstance(input_el[0]).getNumber();
}

function _init(selector) {
    $(selector).each(function() {
        window.intlTelInput(this, {
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
        });
    })
}