/** @module data/hotel */

import 'devbridge-autocomplete/dist/jquery.autocomplete';

/**
 * Setup autocomplete for hotel fields
 *
 * @public
 * @param {object} options
 * @returns {void}
 */
export default function setup_hotels_autocomplete(options) {
    if (options == null) {
        options = {};
    }

    options.context_el = options.context_el || $('body');

    let select_el = $('.autocomplete[data-autocomplete-key=hotel]', options.context_el);

    if (!select_el.length) {
        return;
    }

    let url_root = (typeof __url_root !== 'undefined') ? __url_root : URL_ROOT;
    let min_chars = ('min_chars' in options) ? options.min_chars : 1;

    select_el.each(function () {
        let el = $(this);
        let val_el = $('[data-autocomplete-key=hotel][data-autocomplete-field=hotel_id]', options.context_el);

        let initial = val_el.val();

        el.devbridgeAutocomplete({
            minChars: min_chars,
            lookup: function(query, done) {
                val_el.val('');

                let filter_field = el.attr('data-autocomplete-field');


                $.getJSON(
                    `${url_root}api/hotel?fields=id,name&filters=is_active.eq(1),${filter_field}.like(${query})`,
                    function (json) {
                        let sugg = [];

                        for (let row of json.data) {
                            sugg.push({
                                'value': row.name,
                                'data': row,
                            });
                        }
                        done({suggestions: sugg});
                    }
                );
            },
            onSelect: function (suggestion) {
                val_el
                    .val(suggestion.data.id)
                    .attr('value', suggestion.data.id)
                    .attr('title', suggestion.data.id)
                ;

                $(select_el).val(suggestion.value);
            },
            onInvalidateSelection: function() {
                val_el
                    .val('')
                    .attr('value', '')
                    .attr('title', '')
                ;
            }
        });

        el.devbridgeAutocomplete('setOptions', {
            width: 'flex',
        });

        if (initial != '') {
            $.getJSON(
                `${url_root}api/hotel?fields=id,name&filters=id.eq(${initial})`,
                function (json) {
                    let sugg = [];

                    for (let row of json.data) {
                        $('[data-autocomplete-key=hotel][data-autocomplete-field=hotel_id]', options.context_el).val(row.id);
                        el.val(row.name);
                        break;
                    }
                }
            );
        }
    });
}
