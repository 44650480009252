import { STEPS, navigate, startOver } from 'booking-widget/v2/bw';
import { get as get_cache } from 'utils/cache';
import { show as show_exit_confirm } from 'booking-widget/v2/widgets/exit-confirm';

export const setup = (context_el, current_step_key, on_close) => {
    const nav_el = $('.bw-nav', context_el);
    const current_step = STEPS[current_step_key];
    $('.bw-nav__item--' + current_step.name, nav_el).addClass('selected');
    for (let key in STEPS) {
        if (current_step.name !== STEPS[key].name) {
            let step_data = STEPS[key];
            const href = step_data.href();
            if (href.length > 0) {
                $('.bw-nav__item--' + step_data.name, nav_el)
                .attr('href', step_data.href())
                .off('click')
                .on('click', function(e) {
                    e.preventDefault();
                    navigate($(this).attr('href'));
                })
            ;
            }
        }
    }
    setup_close_btn(context_el, current_step_key, on_close);
}

export const setup_close_btn = (context_el, current_step_key, on_click) => {
    let _on_click = on_click;
    if (STEPS.waitlist.name == current_step_key) {
        $('.bw-close a', context_el).attr('href', STEPS.tours.href());

        if (_on_click) {
            $('.bw-close a', context_el).off('click').on('click', _on_click);
        }
    }
    else if (STEPS.confirmed.name == current_step_key) {
        // close
        $('.bw-close a', context_el).off('click').on('click', function(e) {
            e.preventDefault();
            startOver();
            if (_on_click) {
                _on_click();
            }
        });
    }
    else {
        $('.bw-close a', context_el).off('click').on('click', function(e) {
            e.preventDefault();
            show_exit_confirm({
                on_exit: function() {
                    startOver();
                    if (_on_click) {
                        _on_click();
                    }
                }
            });
        });
    }
}