/** @module data/transaction */
// utilities
import { update_data_targets_object } from 'ui/data';
import { get as get_data, post as post_data } from 'data/data';

// #TODO retrieve this stuff from an API instead of hard-coding it

/**
 * Map transaction_methods to friendly names
 *
 * @public
 * @param {?string} transaction_method
 * @returns {string}
 */
export function transaction_method_to_name(transaction_method) {
    switch (transaction_method) {
        case "gc":
            return "Gift Certificate";
        case "cc":
            return "Credit Card";
        case "cash":
            return "Cash";
        case "adjustment":
            return  "Z Admin Payment";
        case "voucher":
            return "Voucher";
    }
}


/**
 * Map types to friendly names
 *
 * @public
 * @param {?string} transaction_type
 * @returns {string}
 */
export function transaction_type_to_name(transaction_type) {
    switch (transaction_type) {
        case "charge":
            return "Charge";
        case "refund":
            return "Refund";
        case "credit":
            return "Credit";
    }
}

export function print(transaction_json, reservation_json) {
    // move any existing modals aside
    let $existing = $('.modal-dialog').addClass('aside');

    // get the modal wrapper and put the receipt html in it
    let html = $($('#tpl-transaction-receipt-modal').first().html());
    
    let body;
    if (transaction_json.method == 'cc') {
        body = $($('#tpl-transaction-receipt-' + transaction_json.method).first().html());
    }
    else {
        body = $($('#tpl-transaction-receipt-cash').first().html());
    }

    update_data_targets_object('tour_vessel', reservation_json.tour_vessel[0], body);
    update_data_targets_object('reservation', reservation_json, body);
    update_data_targets_object('reservation-payment-summary', reservation_json, body);
    update_data_targets_object('transaction', transaction_json, body);
    update_data_targets_object('tour', reservation_json.tour[0], body);

    $('.modal-body', html).append(body);

    let print_el = body
        .clone()
        .addClass('print-container')
    ;

    $('body').append(print_el);

    html
        .appendTo('body')
        .modal()
        .on('shown.bs.modal', function (e) {
            $('main')
                .addClass('print-hide')
            ;

            window.print();
        })
        .on('click', '.btn-primary', function (e) {
            // do this after the callback so that the callback still has access to the modal content
            // like form fields, etc.
            $(e.delegateTarget).modal('hide');
        })
        .on('hide.bs.modal', function () {
            $(this).remove();

            // bring back any previous modals
            $existing.removeClass('aside');

            $('.print-container').remove();
            $('main')
                .removeClass('print-hide')
            ;
        })
    ;
}

export const post = (options) => {
    if (options == null) {
        options = {};
    }

    let opts = $.extend(true, {}, {
        endpoint: 'transaction'
    }, options)

    // make sure we don't send cc data!
    delete opts.data.cc_number;
    delete opts.data.cvv_code;
    delete opts.data.exp_month;
    delete opts.data.exp_year;

    return post_data(opts);
}